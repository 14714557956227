import React, { useEffect, useState } from 'react';
import "./PrizeDefinitionConfiguratorRow.scss"
import { PrizeDefinitionViewModel } from '../PrizeDefinition.ViewModel';
import { apiPrizeQuantityForStanding } from '../../../Models/Prizeout/ApiPrizeoutDefinition';
import { prizeoutPrizeType } from '../../../Models/Common/PrizeoutPrizeType';
import IconButton, { IconButtonType } from '../../../components/IconButton/IconButton';
import PrizeTypeSelector from '../../../components/selectors/PrizeTypeSelector';

interface PrizeDefinitionConfiguratorRowProps {
    viewModel: PrizeDefinitionViewModel;
    updatePrizeDefinition: (viewModel: PrizeDefinitionViewModel) => void;
}

const PrizeDefinitionConfiguratorRow: React.FC<PrizeDefinitionConfiguratorRowProps> = ({viewModel, updatePrizeDefinition}) => {
    const [prizeGuid, setPrizeGuid] = useState<string | null>(null);
    const [perStandingsQuantities, setPerStandingsQuantities] = useState<apiPrizeQuantityForStanding[]>([]);
    const [participationQuantity, setParticipationQuantity] = useState<number>(0);
    const [perWinQuantity, setPerWinQuantity] = useState<number>(0);
    const [perTieQuantity, setPerTieQuantity] = useState<number>(0);
    const [perLossQuantity, setPerLossQuantity] = useState<number>(0);
    const [differentiator, setDifferentiator] = useState<string>('');
    const [prizeType, setPrizeType] = useState<prizeoutPrizeType>(prizeoutPrizeType.PrizePoints);

    useEffect(() => {
        setPrizeGuid(viewModel.prizeGuid);
        setPerStandingsQuantities(viewModel.perStandingsQuantities);
        setParticipationQuantity(viewModel.participationQuantity);
        setPerWinQuantity(viewModel.perWinQuantity ?? 0);
        setPerTieQuantity(viewModel.perTieQuantity ?? 0);
        setPerLossQuantity(viewModel.perLossQuantity ?? 0);
        setDifferentiator(viewModel.differentiator ?? 0);
        setPrizeType(viewModel.type);
    }, [viewModel]);

    const differentiatorChange = (value: string) => {
        setDifferentiator(value);
        viewModel.differentiator = value;
        updatePrizeDefinition(viewModel);
    }

    const participationQuantityChange = (value: number) => {
        setParticipationQuantity(value);
        viewModel.participationQuantity = value;
        updatePrizeDefinition(viewModel);
    }

    const perWinQuantityChange = (value: number) => {
        setPerWinQuantity(value);
        viewModel.perWinQuantity = value;
        updatePrizeDefinition(viewModel);
    }

    const perTieQuantityChange = (value: number) => {
        setPerTieQuantity(value);
        viewModel.perTieQuantity = value;
        updatePrizeDefinition(viewModel);
    }

    const perLossQuantityChange = (value: number) => {
        setPerLossQuantity(value);
        viewModel.perLossQuantity = value;
        updatePrizeDefinition(viewModel);
    }
    
    const prizeTypeChange = (value: prizeoutPrizeType) => {
        setPrizeType(value);
        viewModel.type = value;
        updatePrizeDefinition(viewModel);
    }

    return (
        <div className="prizeoutDefinitionElement">
            <div className="prizeoutDefinitionElementColumn-data">
                <div className="prizeoutDefinitionElementTypeRow">
                    <div className="prizeoutDefinitionElementItem">
                        <PrizeTypeSelector value={prizeType} onChange={prizeTypeChange} />
                    </div>
                    <div className="prizeoutDefinitionElementItem">
                        <input
                            className="glass-input"
                            type="text"
                            name="differentiator"
                            value={differentiator}
                            onChange={(e) => differentiatorChange(e.target.value)}
                            />
                        <label className="prizeoutDefinition-label italic">Optional Differentiator</label>
                    </div>
                </div>
                <div className="prizeoutDefinitionElementQuantitiesRow">
                    <div className="prizeoutDefinitionElementItem">
                    <input
                        className="glass-number-input"
                        type="number"
                        name="participationQuantity"
                        value={participationQuantity}
                        onChange={(e) => participationQuantityChange(e.target.valueAsNumber)}
                        />
                        <label className="prizeoutDefinition-label">Participation</label>
                    </div>
                    <div className="prizeoutDefinitionElementItem">
                        <input
                            className="glass-number-input"
                            type="number"
                            name="perWinQuantity"
                            value={perWinQuantity}
                            onChange={(e) => perWinQuantityChange(e.target.valueAsNumber)}
                            />
                        <label className="prizeoutDefinition-label">Per win</label>
                    </div>
                    <div className="prizeoutDefinitionElementItem">
                        <input
                            className="glass-number-input"
                            type="number"
                            name="perTieQuantity"
                            value={perTieQuantity}
                            onChange={(e) => perTieQuantityChange(e.target.valueAsNumber)}
                            />
                        <label className="prizeoutDefinition-label">Per tie</label>
                    </div>
                    <div className="prizeoutDefinitionElementItem">
                        <input
                            className="glass-number-input"
                            type="number"
                            name="perLossQuantity"
                            value={perLossQuantity}
                            onChange={(e) => perLossQuantityChange(e.target.valueAsNumber)}
                            />
                        <label className="prizeoutDefinition-label">Per loss</label>
                    </div>
                </div>
            </div>

            <div className="prizeoutDefinitionElementColumn-standings">
                {perStandingsQuantities.map((standingQuantity, index) => (
                    <div key={index} className="prizeoutDefinitionElementStandingRow">
                        <div className="prizeoutDefinitionElementItem">
                        <div className="data-pair">
                            <input
                                className="glass-number-input"
                                type="number"
                                name={`perStandingsStartPosition-${index}`}
                                value={standingQuantity.positionRangeStart}
                                onChange={(e) => {
                                    const newValue = [...perStandingsQuantities];
                                    newValue[index].positionRangeStart = e.target.valueAsNumber;
                                    setPerStandingsQuantities(newValue);
                                    viewModel.perStandingsQuantities = newValue;
                                    updatePrizeDefinition(viewModel);
                                }}
                            />
                            {index === perStandingsQuantities.length - 1 && (
                                <label className="prizeoutDefinition-label">Start Position</label>
                            )}
                        </div>
                        </div>
                        <div className="prizeoutDefinitionElementItem">
                            <div className="data-pair">
                            <input
                                className="glass-number-input"
                                type="number"
                                name={`perStandingsEndPosition-${index}`}
                                value={standingQuantity.positionRangeEnd}
                                onChange={(e) => {
                                    const newValue = [...perStandingsQuantities];
                                    newValue[index].positionRangeEnd = e.target.valueAsNumber;
                                    setPerStandingsQuantities(newValue);
                                    viewModel.perStandingsQuantities = newValue;
                                    updatePrizeDefinition(viewModel);
                                }}
                            />
                            {index === perStandingsQuantities.length - 1 && (
                                <label className="prizeoutDefinition-label">End Position</label>
                            )}
                        </div>
                        </div>
                        <div className="prizeoutDefinitionElementItem">
                            <div className="data-pair">
                                <input
                                    className="glass-number-input"
                                    type="number"
                                    name={`perStandingsQuantity-${index}`}
                                    value={standingQuantity.quantity}
                                    onChange={(e) => {
                                        const newValue = [...perStandingsQuantities];
                                        newValue[index].quantity = e.target.valueAsNumber;
                                        setPerStandingsQuantities(newValue);
                                        viewModel.perStandingsQuantities = newValue;
                                        updatePrizeDefinition(viewModel);
                                    }}
                                />
                                {index === perStandingsQuantities.length - 1 && (
                                    <label className="prizeoutDefinition-label">Prize Quantity</label>
                                )}
                            </div>
                        </div>
                        <div className="prizeoutDefinitionDeleteButton">
                            <IconButton isEnabled={true} hasShadow={false} iconType={IconButtonType.Cross} label="Delete" hasBackground={false} onPress={() => {
                                const newValue = [...perStandingsQuantities];
                                newValue.splice(index, 1);
                                setPerStandingsQuantities(newValue);
                                viewModel.perStandingsQuantities = newValue;
                                updatePrizeDefinition(viewModel)
                            }}/>
                        </div>
                    </div>
                ))}
                <button className="prizeoutDefinitionAddStandingRangeButton" onClick={() => {
                    viewModel.AddNewStandingRange();
                    updatePrizeDefinition(viewModel);
                }}> Add Standings Range </button>
            </div>
        </div>
    );
}

export default PrizeDefinitionConfiguratorRow;