import { BehaviorSubject, combineLatest } from "rxjs";
import { ApiConnectService } from "../../Services/ApiConnectService";
import { TournamentDataService } from "../../Services/TournamentDataService";
import { streamDataTransfer } from "../../Models/Inbound/StreamDataTransfer";
import { matchViewModel, streamMatchViewModel } from "../Match/Match.ViewModel";
import { streamDataViewModel } from "../StreamDataControls/StreamDataControls.ViewModel";
import { pairingsViewModel } from "../PairingsList/PairingsList.ViewModel";
import { NavbarData, NavbarProperties, SyncToolConfig, SyncToolConfigData } from "../../components/navbar/Navbar.ViewModel";
import { streamDataChannel } from "../../Models/Common/StreamDataChannel";
import { NavbarSyncToolIcon } from "../../components/navbar/NavbarSyncTool/NavbarSyncTool";
import { CommonFormatsService as formats } from "../../Services/CommonFormatsService";
import { competitorDivision } from "../../Models/Common/CompetitorDivision";

export class unsavedChangesViewModel {
    isAnyChanges: boolean;
    isStreamDataChannelChanged: boolean;
    isStreamMatchChanged: boolean;
    isFeatureMatchChanged: boolean;
    isPlayersToWatchChanged: boolean;
}

export enum EditStreamMatchAction {
    Delete,
    Promote,
    Demote,
    ToggleSwitchPlayers
}

export class pairingsDashboardViewModel {
    navbar: NavbarProperties
    eventId: string;
    roundRequested: number | null;
    isLoadingPairings: boolean;
    isLoadingStreamData: boolean;
    isReadOnly: boolean;
    localStreamDataChannel = new BehaviorSubject<streamDataChannel | null>(null);
    highlightedMatch = new BehaviorSubject<matchViewModel | null>(null);
    localPlayersToWatch = new BehaviorSubject<string[]>([]);
    localFeatureMatches = new BehaviorSubject<streamMatchViewModel[]>([]);
    localStreamMatch = new BehaviorSubject<streamMatchViewModel | null>(null);
    pairingsSubject = new BehaviorSubject<pairingsViewModel | null>(null);
    streamData = new BehaviorSubject<streamDataViewModel | null>(null);
    unsavedChanges = new BehaviorSubject<unsavedChangesViewModel>(new unsavedChangesViewModel());
    refreshPairingsTrigger = new BehaviorSubject<void>(void 0);

    acs = new ApiConnectService();
    tournamentService = new TournamentDataService(this.acs);

    constructor(
        eventId: string,
        navbarProperties: NavbarProperties,
        division: competitorDivision,
        roundNumber: number | null,
        isReadOnly: boolean = false) {
        this.eventId = eventId;
        this.navbar = navbarProperties;
        this.roundRequested = roundNumber;
        this.isLoadingPairings = true;
        this.isLoadingStreamData = true;
        this.isReadOnly = isReadOnly;

        this.navbar.syncToolConfig.isSyncFeatureEnabled.next(true);
        this.navbar.syncToolConfig.isSyncActive.next(false);
        this.navbar.syncToolConfig.data.next(new SyncToolConfigData(
            false,
            null,
            NavbarSyncToolIcon.Sync,
            "Pairings",
            "Pairings Data"
        ));

        // Configure continuous pairings sync
        this.tournamentService.GetPairingsDataContinuous(
            this.eventId,
            division,
            roundNumber,
            5000,
            this.navbar.syncToolConfig.isSyncActive,
            this.refreshPairingsTrigger
        ).subscribe(apiPairings => {
            console.log("Received continuous pairings data at " + new Date().toLocaleTimeString());
            if (apiPairings && apiPairings.result) {
                var pairings = new pairingsViewModel(apiPairings.result)
                this.pairingsSubject.next(pairings ?? null);
                this.updateStreamMatchSelection();
                this.updateFeatureMatchSelection();
                this.updatePlayersToWatch();
                console.log("Pairings dashboard updated with " + pairings?.matches.length + " matches");
                this.navbar.data.next(
                    new NavbarData(
                        "Round " + this.pairingsSubject.getValue()?.roundNumber + " " + competitorDivision.name(division) + " Pairings ",
                        this.eventId)
                );
            }
            this.isLoadingPairings = false;

            // Refresh Players To Watch for read-only Pairings
            if (this.isReadOnly) {
                this.refreshStreamData();
            }

            this.navbar.syncToolConfig.data.next(new SyncToolConfigData(
                false,
                apiPairings,
                NavbarSyncToolIcon.Sync,
                "Pairings",
                "Updated: " + formats.formatOptionalDateCompact(apiPairings?.result?.lastUpdated),
                "Checked: " + formats.formatOptionalDateCompact(new Date().toISOString()),
            ));
        });

        this.refreshStreamData();

        combineLatest({streamData: this.streamData, localStreamDataChannel: this.localStreamDataChannel, localPlayersToWatch: this.localPlayersToWatch, localFeatureMatches: this.localFeatureMatches, localStreamMatch: this.localStreamMatch}).subscribe(data => {
            var unsavedChanges = new unsavedChangesViewModel();
            unsavedChanges.isStreamDataChannelChanged = JSON.stringify(data.streamData?.streamDataChannel) !== JSON.stringify(data.localStreamDataChannel);
            unsavedChanges.isStreamMatchChanged = JSON.stringify(data.streamData?.streamMatch) !== JSON.stringify(data.localStreamMatch);
            unsavedChanges.isPlayersToWatchChanged = JSON.stringify(data.streamData?.playersToWatch) !== JSON.stringify(data.localPlayersToWatch);
            unsavedChanges.isFeatureMatchChanged = JSON.stringify(data.streamData?.featureMatches) !== JSON.stringify(data.localFeatureMatches);
            unsavedChanges.isAnyChanges = unsavedChanges.isFeatureMatchChanged || unsavedChanges.isPlayersToWatchChanged || unsavedChanges.isStreamMatchChanged;
            this.unsavedChanges.next(unsavedChanges);
        });

        this.refreshData();
    }

    refreshData = () => {
        this.refreshPairingsTrigger.next();
    }

    refreshStreamData = () => {
        this.tournamentService.GetStreamData(this.eventId).subscribe(apiStreamData => {
            if (apiStreamData.result) {
                var streamData = new streamDataViewModel(this.eventId, apiStreamData.result);
                this.streamData.next(streamData);
                this.resetStreamDataChannelSelection();
                this.updateStreamMatchSelection(true);
                this.updateFeatureMatchSelection(true);
                this.updatePlayersToWatch(true);
                console.log("Pairings dashboard stream data updated, selected match is table " + streamData.streamMatch?.tableNumber);
            } else {
                var streamData = new streamDataViewModel(this.eventId);
                this.streamData.next(streamData);
                this.resetStreamDataChannelSelection();
            }
            this.isLoadingStreamData = false;
        });
    }

    uploadStreamData = () => {
        var viewModel = this.streamData.getValue()
        if (viewModel) {
            viewModel.setUploading()
            this.streamData.next(null)
            this.streamData.next(viewModel)
        }
        var streamMatch = this.localStreamMatch.getValue();
        var convertedStreamMatch = streamMatch ? streamMatch.toStreamMatchTransfer() : null;
        var streamData = new streamDataTransfer(
            this.eventId,
            this.localStreamDataChannel.getValue(),
            convertedStreamMatch ? convertedStreamMatch : null,
            this.localFeatureMatches.getValue().map(m => m.toStreamMatchTransfer()),
            this.localPlayersToWatch.getValue()
        );

        this.tournamentService.PostStreamData(streamData, this.eventId).subscribe(streamData => {
            console.log("Stream data uploading...");
            if(streamData.result) {
                var updatedStreamData = new streamDataViewModel(this.eventId, streamData.result, streamData);
                this.streamData.next(null);
                this.streamData.next(updatedStreamData);
            }
        });
    }

    toggleHighlightedMatch = (match: matchViewModel) => {
        console.log("Match highlighted: Table " + match.tableNumber)
        if (this.isReadOnly) {
            console.log("Read only mode, ignoring highlight request");
            return;
        }
        if (this.highlightedMatch.getValue() === match) {
            this.highlightedMatch.next(null);
        } else {
            this.highlightedMatch.next(match);
        }
    }

    toggleStreamMatch = (match: matchViewModel) => {
        console.log("Toggle stream match on table: " + match.tableNumber)
        var selectionViewModel = match.toStreamMatchViewModel();
        if (this.compareStreamMatches(this.localStreamMatch.getValue(), selectionViewModel)) {
            this.localStreamMatch.next(null);
        } else {
            selectionViewModel.featureMatchIndex = 0;
            this.localStreamMatch.next(selectionViewModel);
        }
        this.updateStreamMatchSelection();
    }

    togglePlayerToWatch = (playerId: string) => {
        console.log("Toggle player to watch with ID: " + playerId)
        var localPTW = this.localPlayersToWatch.getValue();
        if (localPTW.includes(playerId)) {
            const index = localPTW.indexOf(playerId);
            localPTW.splice(index, 1);
        } else {
            localPTW.push(playerId);
        }
        localPTW.sort();
        this.localPlayersToWatch.next([...localPTW]);
        this.updatePlayersToWatch();
    }

    toggleFeatureMatch = (ccMatch: matchViewModel) => {
        console.log("Toggle feature match at table " + ccMatch.tableNumber)
        var selectionViewModel = ccMatch.toStreamMatchViewModel();
        var localFM = this.localFeatureMatches.getValue();
        const index = localFM.findIndex(match => this.compareStreamMatches(match, selectionViewModel));
        if (index !== -1) {
            localFM.splice(index, 1);
        } else {
            localFM.push(selectionViewModel);
        }
        localFM.forEach((item, index) => {
            item.featureMatchIndex = index;
        });
        this.localFeatureMatches.next(localFM);
        this.updateFeatureMatchSelection();
    }

    clearPlayersToWatch = () => {
        this.localPlayersToWatch.next([]);
        this.updatePlayersToWatch();
    }

    clearFeatureMatches = () => {
        this.localFeatureMatches.next([]);
        this.updateFeatureMatchSelection();
    }

    editStreamDataChannel = (channel: streamDataChannel | null) => {
        console.log("Edit stream data channel: " + channel?.toString())
        this.localStreamDataChannel.next(channel);
    }

    editStreamMatch = (match: streamMatchViewModel | null, action: EditStreamMatchAction) => {
        var localSM = this.localStreamMatch.getValue();
        if (localSM === null || match === null) { return; }
        switch (action) {
            case EditStreamMatchAction.Delete:
                localSM = null;
            break;
            case EditStreamMatchAction.Demote:
                // Not implemented as collection is only 1 item.
            break;
            case EditStreamMatchAction.Promote:
                // Not implemented as collection is only 1 item.
            break;
            case EditStreamMatchAction.ToggleSwitchPlayers:
                localSM.shouldSwitchPlayers = !localSM.shouldSwitchPlayers;
            break;
        }
        this.localStreamMatch.next(localSM);
        this.updateStreamMatchSelection();
    }

    editFeatureMatch = (match: streamMatchViewModel | null, action: EditStreamMatchAction) => {
        if (match === null) {
            return;
        }
        var localFM = this.localFeatureMatches.getValue();
        const index = localFM.findIndex(item => item.tableNumber === match.tableNumber);

        switch (action) {
            case EditStreamMatchAction.Delete:
                if (index > -1) {
                    localFM.splice(index, 1);
                }
            break;
            case EditStreamMatchAction.Demote:
                if (index > -1 && index < localFM.length) {
                    const item = localFM[index];
                    localFM.splice(index, 1);
                    localFM.splice(index + 1, 0, item);
                }
            break;
            case EditStreamMatchAction.Promote:
                if (index > 0 && index < localFM.length) {
                    const item = localFM[index];
                    localFM.splice(index, 1);
                    localFM.splice(index - 1, 0, item);
                }
            break;
            case EditStreamMatchAction.ToggleSwitchPlayers:
                localFM[index].shouldSwitchPlayers = !localFM[index].shouldSwitchPlayers;
            break;
        }
        localFM.forEach((item, index) => {
            item.featureMatchIndex = index;
        });
        this.localFeatureMatches.next(localFM);
        this.updateFeatureMatchSelection();
    }

    clearStreamMatch = () => {
        this.localStreamMatch.next(null);
        this.updateStreamMatchSelection();
    }

    resetLocalChanges = () => {
        this.resetStreamDataChannelSelection();
        this.updatePlayersToWatch(true);
        this.updateFeatureMatchSelection(true);
        this.updateStreamMatchSelection(true);
    }

    private compareStreamMatches(a: streamMatchViewModel | null, b: streamMatchViewModel | null): boolean {
        if (a === null && b === null ) {
            return true;
        }

        if (a === null || b === null ) {
            return false;
        }

        return (a.tableNumber === b.tableNumber &&
                a.player1.playerId === b.player1.playerId &&
                a.player2?.playerId === b.player2?.playerId &&
                a.roundNumber === b.roundNumber);
    }

    private resetStreamDataChannelSelection() {
        var streamDataChannel = this.streamData.getValue()?.streamDataChannel;
        if (streamDataChannel) {
            this.localStreamDataChannel.next(streamDataChannel);
        }
    }

    private updateStreamMatchSelection(isRefresh: boolean = false) {
        if (isRefresh) {
            var streamMatch = this.streamData.getValue()?.streamMatch;
            if (streamMatch) {
                this.localStreamMatch.next(streamMatch.deepCopy());
            }
        }
        var pairings = this.pairingsSubject.getValue();
        if (pairings != null) {
            pairings?.matches.map(match => {
                match.isStreamMatch = false;
            })
            if (this.localStreamMatch != null) {
                pairings.matches.map(match => {
                    if (match.tableNumber === this.localStreamMatch.getValue()?.tableNumber) {
                        match.isStreamMatch = true;
                    }
                })
            }
        }
        this.pairingsSubject.next(pairings);
    }

    private updateFeatureMatchSelection(isRefresh: boolean = false) {
        if (isRefresh) {
            var newMatches = this.streamData.getValue()?.featureMatches ?? [];
            newMatches.sort((a, b) => a.featureMatchIndex - b.featureMatchIndex);
            newMatches = newMatches.filter((item) => item !== null).map((item) => item.deepCopy());
            this.localFeatureMatches.next(newMatches);
        }
        var pairings = this.pairingsSubject.getValue();
        if (pairings != null) {
            pairings?.matches.map(match => {
                match.isFeatureMatch = false;
            })
            this.localFeatureMatches.getValue().forEach(featureMatch => {
                pairings?.matches.map(match => {
                    if (match.tableNumber === featureMatch.tableNumber) {
                        match.isFeatureMatch = true;
                    }
                })
            })
        }
        this.pairingsSubject.next(pairings);
    }

    private updatePlayersToWatch(isRefresh: boolean = false) {
        if (isRefresh) {
            var playersToWatch = this.streamData.getValue()?.playersToWatch ?? [];
            var localPlayersToWatchDeepClone = JSON.parse(JSON.stringify(playersToWatch));
            this.localPlayersToWatch.next(localPlayersToWatchDeepClone);
        }
        var pairings = this.pairingsSubject.getValue();
        if (pairings != null) { 
            pairings?.matches.map(match => {
                match.player1.isPlayerToWatch = false;
                if (match.player2) {
                    match.player2.isPlayerToWatch = false;
                }
            })
            this.localPlayersToWatch.getValue().forEach(playerId => {
                pairings?.matches.map(match => {
                    if (match.player1.playerId === playerId) {
                        match.player1.isPlayerToWatch = true;
                    }
                    if (match.player2 && match.player2.playerId === playerId) {
                        match.player2.isPlayerToWatch = true;
                    }
                })
            })
        } 
        this.pairingsSubject.next(pairings);
    }
}