import { eventType } from "../../Models/Common/EventType";
import { prizeoutPrizeType } from "../../Models/Common/PrizeoutPrizeType";
import { apiPrizeDefinition, apiPrizeoutDefinition, apiPrizeQuantityForStanding } from "../../Models/Prizeout/ApiPrizeoutDefinition";
import { apiPrizeDefinitionTransfer } from "../../Models/Prizeout/ApiPrizeoutDefinitionTransfer";

export class PrizeDefinitionViewModel {
    internalUniqueId: string;
    prizeGuid: string | null;
    perStandingsQuantities: apiPrizeQuantityForStanding[];
    participationQuantity: number;
    perWinQuantity: number | null;
    perTieQuantity: number | null;
    perLossQuantity: number | null;
    differentiator: string;
    type: prizeoutPrizeType;

  constructor(event: eventType, existingDefinition: apiPrizeDefinition | null) {
    this.internalUniqueId = Math.random().toString(36);
    if (existingDefinition === null) {
      this.prizeGuid = null;
      this.perStandingsQuantities = [];
      this.participationQuantity = 0;
      this.perWinQuantity = null;
      this.perTieQuantity = null;
      this.perLossQuantity = null;
      this.differentiator = "";
      this.type = event === eventType.SideEvent ? prizeoutPrizeType.PrizePoints : prizeoutPrizeType.Booster;
    } else {
      this.prizeGuid = existingDefinition.prizeGuid;
      this.perStandingsQuantities = existingDefinition.perStandingsQuantities;
      this.participationQuantity = existingDefinition.participationQuantity;
      this.perWinQuantity = existingDefinition.perWinQuantity;
      this.perTieQuantity = existingDefinition.perTieQuantity;
      this.perLossQuantity = existingDefinition.perLossQuantity;
      this.differentiator = existingDefinition.differentiator;
      this.type = existingDefinition.type;
    }
  }

  public ToApiPrizeDefinitionTransfer(): apiPrizeDefinitionTransfer {
    return {
      prizeGuid: this.prizeGuid,
      perStandingsQuantities: this.perStandingsQuantities,
      participationQuantity: this.participationQuantity,
      perWinQuantity: this.perWinQuantity,
      perTieQuantity: this.perTieQuantity,
      perLossQuantity: this.perLossQuantity,
      differentiator: this.differentiator,
      type: this.type
    };
  }

  public AddNewStandingRange() {
    this.perStandingsQuantities.push({
      positionRangeStart: 0,
      positionRangeEnd: 0,
      quantity: 0
    });
  }
}