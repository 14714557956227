import { eventType } from "../../Models/Common/EventType";
import { prizeoutPrizeType } from "../../Models/Common/PrizeoutPrizeType";
import { apiPrizeoutDefinition } from "../../Models/Prizeout/ApiPrizeoutDefinition";
import { apiPrizeoutDefinitionTransfer } from "../../Models/Prizeout/ApiPrizeoutDefinitionTransfer";
import { PrizeDefinitionViewModel } from "./PrizeDefinition.ViewModel";
import { PrizeDefinitionConfiguratorDivision } from "./PrizeDefinitionConfiguratorDivision";

export enum PrizeDefinitionValidationError {
  default
}

export class PrizeDefinitionConfiguratorViewModel {
  division: PrizeDefinitionConfiguratorDivision;
  definitions: PrizeDefinitionViewModel[];

  constructor(event: eventType, division: PrizeDefinitionConfiguratorDivision, apiPrizeout: apiPrizeoutDefinition | null) {
    this.division = division;
    if (apiPrizeout === null) {
      this.definitions = [];
    } else {
      this.definitions = apiPrizeout.prizeDefinitions?.map((definition) => new PrizeDefinitionViewModel(event, definition)) ?? [];
    }
  }

  public AddNewDefinition() {
    const placeholderDefinition = new PrizeDefinitionViewModel(eventType.SideEvent, null);
    placeholderDefinition.prizeGuid = null;
    placeholderDefinition.perStandingsQuantities = [];
    placeholderDefinition.participationQuantity = 0;
    placeholderDefinition.perWinQuantity = 0;
    placeholderDefinition.perTieQuantity = 0;
    placeholderDefinition.perLossQuantity = 0;
    placeholderDefinition.differentiator = "";
    placeholderDefinition.type = prizeoutPrizeType.PrizePoints;
    this.definitions.push(placeholderDefinition);
  }

  public DeleteDefinition(definition: PrizeDefinitionViewModel) {
    this.definitions = this.definitions.filter((d) => d.internalUniqueId !== definition.internalUniqueId);
  }

  public UpdateDefinition(definition: PrizeDefinitionViewModel) {
    const index = this.definitions.findIndex((d) => d.internalUniqueId === definition.internalUniqueId);
    if (index === -1) {
      this.definitions.push(definition);
    } else {
      this.definitions[index] = definition;
    }
  }

  public ToApiPrizeoutDefinitionTransfer(): apiPrizeoutDefinitionTransfer[] {
    if (this.division === PrizeDefinitionConfiguratorDivision.AllDivisions) {
      return [
      {
        division: PrizeDefinitionConfiguratorDivision.toCompetitorDivision(PrizeDefinitionConfiguratorDivision.Junior),
        prizeDefinitions: this.definitions.map((definition) => definition.ToApiPrizeDefinitionTransfer())
      },
      {
        division: PrizeDefinitionConfiguratorDivision.toCompetitorDivision(PrizeDefinitionConfiguratorDivision.Senior),
        prizeDefinitions: this.definitions.map((definition) => definition.ToApiPrizeDefinitionTransfer())
      },
      {
        division: PrizeDefinitionConfiguratorDivision.toCompetitorDivision(PrizeDefinitionConfiguratorDivision.Master),
        prizeDefinitions: this.definitions.map((definition) => definition.ToApiPrizeDefinitionTransfer())
      }
      ];
    } else {
      return [
      {
        division: PrizeDefinitionConfiguratorDivision.toCompetitorDivision(this.division),
        prizeDefinitions: this.definitions.map((definition) => definition.ToApiPrizeDefinitionTransfer())
      }
      ];
    }
  }
}