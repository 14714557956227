import React, { useEffect, useState } from 'react';
import "./ActionButton.scss"
import { FaChevronDown } from "react-icons/fa";
import { FaPlus } from "react-icons/fa";


interface ActionButtonProps {
    text: string;
    onPress: () => void;
    iconType: ActionButtonType;
}

export enum ActionButtonType {
    Create,
    DownChevron,
    None
}

const ActionButton: React.FC<ActionButtonProps> = ({text, onPress, iconType}) => {

    useEffect(() => {
    }, [text, onPress]);

    let icon = <div/>;

    switch (iconType) {
        case ActionButtonType.Create:
            icon = <FaPlus className='action-icon'/>;
            break;
        case ActionButtonType.DownChevron:
            icon = <FaChevronDown className='action-icon'/>;
            break;
        case ActionButtonType.None:
            icon = <div/>;
            break;
    }

    return (
        <div className="action-button" onClick={onPress}>
            <label className='action-label'>{text}</label>
            {icon}
        </div>
    );
};

export default ActionButton;;