import { useState, useEffect, Fragment } from "react";
import '../../components/glassTheme/Common.scss';
import '../../components/glassTheme/Switch.scss';
import './PrizeoutStandingsList.scss';
import React from 'react';
import { BehaviorSubject, Subscription } from "rxjs";
import SearchBar from "../../components/search-bar/SearchBar";
import { GiKiwiBird } from "react-icons/gi";
import FilterButton, { FilterButtonType } from "../../components/filter-button/FilterButton";
import { PrizeoutStandingViewModel } from "../PrizeoutStanding/PrizeoutStanding.ViewModel";
import PrizeoutStanding from "../PrizeoutStanding/PrizeoutStanding";
import ActionButton, { ActionButtonType } from "../../components/action-button/ActionButton";
import { PrizeoutEventData } from "../PrizeoutEventSelector/PrizeoutEventSelector.ViewModel";
import ActionField, { ActionFieldType } from "../../components/action-field/ActionField";

interface PrizeoutStandingsSubviewProps {
    combinedStandingsSubject: BehaviorSubject<PrizeoutStandingViewModel[]>;
    selectedEventsSubject: BehaviorSubject<PrizeoutEventData[]>;
    getStatistics: () => string;
    onChangeSelectedEvents: () => void;
    onUsernameChange: (username: string) => void;
    currentUsername: BehaviorSubject<string>;
    editPrizeoutComment: (prizeoutUid: string, comment: string | null) => void;
    editPrizeAwarded: (prizeoutUid: string, prizeGuid: string, prizePointsAwarded: number | null) => void;
}

enum PrizeoutStandingsFilterType {
    Important,
    Dropped,
    Unsynced,
    Unprized,
    FullyPrized,
    Search,
    Default,
}

const PrizeoutStandingsList: React.FC<PrizeoutStandingsSubviewProps>
    = ({ 
        combinedStandingsSubject, 
        selectedEventsSubject,
        getStatistics,
        onChangeSelectedEvents, 
        onUsernameChange,
        currentUsername,
        editPrizeoutComment, 
        editPrizeAwarded 
    }) => {
    const [standings, setStandings] = useState(combinedStandingsSubject.value);
    const [selectedEvents, setSelectedEvents] = useState(selectedEventsSubject.value);
    const [highlightedStandingState, setHighlightedStandingState] = useState<PrizeoutStandingViewModel | null>(null);
    const [overrideShortenedStandingsList, setOverrideShortenedStandingsList] = useState(false);
    const [standingsFilter, setStandingsFilter] = useState<PrizeoutStandingsFilterType>(PrizeoutStandingsFilterType.Default);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [userName, setUsername] = useState<string>('');
    const [statistics, setStatistics] = useState<string>('');
    const [showStatistics, setShowStatistics] = useState(false);

    useEffect(() => {
            var subs: Subscription[] = [];
            subs.push(combinedStandingsSubject.subscribe((combinedStandings) => setStandings([...combinedStandings ?? []])));
            subs.push(selectedEventsSubject.subscribe((selectedEvents) => setSelectedEvents([...selectedEvents ?? []])));
            subs.push(currentUsername.subscribe((username) => setUsername(username)));
            return () => subs.forEach(s => s.unsubscribe());
    }, [combinedStandingsSubject, selectedEventsSubject]);

    const toggleStatistics = () => {
        var isShowing = showStatistics;
        setShowStatistics(!isShowing);
        if (!isShowing) {
            setStatistics(getStatistics());
        }
    }

    const filteredStandings = standings.filter(standing => {
        switch (standingsFilter) {
            case PrizeoutStandingsFilterType.Important:
                return standing.commentIsImportant();
            case PrizeoutStandingsFilterType.Dropped:
                return standing.isDropped();
            case PrizeoutStandingsFilterType.Unsynced:
                return standing.isEdited();
            case PrizeoutStandingsFilterType.Unprized:
                return !standing.isFullyPrizedOut() || standing.isEdited();
            case PrizeoutStandingsFilterType.FullyPrized:
                return standing.prizesDueAndFullyPrizedOut() || standing.isEdited();
            case PrizeoutStandingsFilterType.Search:
                if (searchTerm.length < 3 && searchTerm.length > 0) {
                    return false;
                }
                return standing.fullName().toLowerCase().includes(searchTerm.toLowerCase())
            default:
                return true;
        }
    });

    const StandingsFilterValueChange = (value: PrizeoutStandingsFilterType) => {
        if (standingsFilter === value) {
            setStandingsFilter(PrizeoutStandingsFilterType.Default);
            return;
        }
        setStandingsFilter(value);
        setSearchTerm("");
    }

    const searchTermValueChange = (value: string) => {
        if (value.length > 0 ) {
            setStandingsFilter(PrizeoutStandingsFilterType.Search);
        } else {
            setStandingsFilter(PrizeoutStandingsFilterType.Default);
        }
        setSearchTerm(value);
    }

    const usernameValueChange = (value: string) => {
        setUsername(value);
        onUsernameChange(value);
    }

    const isSearchTermInvalid = (): boolean => {
        return standingsFilter === PrizeoutStandingsFilterType.Search
            && searchTerm.length > 0
            && searchTerm.length < 3;
    }

    return (
        <div className="standings-list">
        <Fragment key={filteredStandings.length}>
            <ul className="standings-list-items">
                {filteredStandings
                    .sort((a, b) => (a.division ?? 0) - (b.division ?? 0) || (a.position ?? 0) - (b.position ?? 0))
                    .slice(0, overrideShortenedStandingsList ? filteredStandings.length : 15)
                    .map(standing => (
                    <li className="standings-list-item" key={standing.prizeoutUid}>
                        <PrizeoutStanding
                            viewModel={standing}
                            editPrizeoutComment={editPrizeoutComment}
                            editPrizeAwarded={editPrizeAwarded}
                        />
                    </li>
                ))}
                    <div className="prizeout-list-footer">
                        {standings.filter(standing => standing.isEdited()).length ? (
                            <label className="glass-h2 bold">{standings.filter(standing => standing.isEdited()).length} unsynced edits.</label>
                        ) : (
                            <label className="glass-h3 italic">All edits synced</label>
                        )}
                        
                        {filteredStandings.length > 15 && (
                            <label className="glass-h2 italic" 
                                onClick={() => setOverrideShortenedStandingsList(!overrideShortenedStandingsList)}>
                                {overrideShortenedStandingsList ? 'Click here to return to optimised shortened list' : `${filteredStandings.length - 15} more results. Use filters or search to view them.`}
                            </label>
                        )}
                        <label className="prizeoutStandings-statisticsButton" onClick={() => toggleStatistics()}>statistics</label>
                        {showStatistics && (
                            <div className="prizeoutStandings-statisticsArea">
                                <label className="prizeoutStandings-statisticsButton" onClick={() => setStatistics(getStatistics())}>update</label>
                                <label className="glass-caption tLeft" style={{ whiteSpace: 'pre-wrap' }}>{statistics}</label>
                            </div>
                        )}
                    </div>
                {filteredStandings.length === 0 && !isSearchTermInvalid() && <div className="searchError">
                    <label className="glass-h2"><i>No records found for current events and filters.</i></label>
                    <GiKiwiBird className="searchErrorIcon"/>
                </div>}
                {filteredStandings.length === 0 && isSearchTermInvalid() && <div className="searchError">
                    <label className="glass-h2"><i>Enter three or more characters to search.</i></label>
                    <GiKiwiBird className="searchErrorIcon"/>
                </div>}
            </ul>
        </Fragment>
        <div className="adminControls">
            <ActionField
                text={userName}
                onTextChange={usernameValueChange}
                iconType={ActionFieldType.Admin}/>
            <ActionButton
                text={selectedEvents.length + " events selected"}
                onPress={onChangeSelectedEvents}
                iconType={ActionButtonType.DownChevron}/>
        </div>
        <div className="filterControls">
            <FilterButton
                isToggled={standingsFilter === PrizeoutStandingsFilterType.Important}
                onToggle={() => StandingsFilterValueChange(PrizeoutStandingsFilterType.Important)}
                iconType={FilterButtonType.Important}/>
            <FilterButton
                isToggled={standingsFilter === PrizeoutStandingsFilterType.Dropped}
                onToggle={() => StandingsFilterValueChange(PrizeoutStandingsFilterType.Dropped)}
                iconType={FilterButtonType.Dropped}/>
            <FilterButton
                isToggled={standingsFilter === PrizeoutStandingsFilterType.Unsynced}
                onToggle={() => StandingsFilterValueChange(PrizeoutStandingsFilterType.Unsynced)}
                iconType={FilterButtonType.Unsynced}/>
            <FilterButton
                isToggled={standingsFilter === PrizeoutStandingsFilterType.Unprized}
                onToggle={() => StandingsFilterValueChange(PrizeoutStandingsFilterType.Unprized)}
                iconType={FilterButtonType.Unprized}/>
            <FilterButton
                isToggled={standingsFilter === PrizeoutStandingsFilterType.FullyPrized}
                onToggle={() => StandingsFilterValueChange(PrizeoutStandingsFilterType.FullyPrized)}
                iconType={FilterButtonType.FullyPrized}/>
            <SearchBar
                searchTerm={searchTerm}
                onSearch={searchTermValueChange}/>
        </div>
    </div>
    );
}

export default PrizeoutStandingsList;