import { apiStanding } from "../../Models/Inbound/Standing";

export class StandingViewModel {
    position: string;
    playerId: string | null;
    firstName: string;
    lastName: string;
    countryCode: string | null;
    wins: number;
    losses: number;
    ties: number;
    matchPoints: number;
    dropRound: number | null;
    opponentWinPercentage: number | null;
    opponentsOpponentsWinPercentage: number | null;

    constructor(apiStandings: apiStanding) {
        this.position = apiStandings.position;
        this.playerId = apiStandings.playerId;
        this.firstName = apiStandings.firstName;
        this.lastName = apiStandings.lastName;
        this.countryCode = apiStandings.countryCode;
        this.wins = apiStandings.wins;
        this.losses = apiStandings.losses;
        this.ties = apiStandings.ties;
        this.matchPoints = apiStandings.matchPoints;
        this.dropRound = apiStandings.dropRound;
        this.opponentWinPercentage = apiStandings.opponentWinPercentage;
        this.opponentsOpponentsWinPercentage = apiStandings.opponentsOpponentsWinPercentage;
    }

    public isDropped(): boolean {
        return this.dropRound !== null;
    }

    public fullName(): string {
        return `${this.firstName} ${this.lastName}`;
    }
}