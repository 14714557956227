import { StandingViewModel } from "../Standing/Standing.ViewModel";
import { apiStandings } from "../../Models/Inbound/ApiStandings";
import { BehaviorSubject } from "rxjs";
import { loadingState } from "../../Models/Common/LoadingState";

export class StandingsListViewModel {
    eventId: string;
    lastUpdated: string;
    division: number;
    round: number;
    isFinal: boolean;
    standings: StandingViewModel[];
    loadingStateSubject: BehaviorSubject<loadingState>;

    constructor(apiStandings: apiStandings) {
        this.eventId = apiStandings.eventId;
        this.lastUpdated = apiStandings.lastUpdated;
        this.division = apiStandings.division;
        this.round = apiStandings.round;
        this.isFinal = apiStandings.isFinal;
        this.standings = [];
        this.loadingStateSubject = new BehaviorSubject<loadingState>(loadingState.loading);
        apiStandings.standings.forEach(standing => {
            try {
                var convertedMatch = new StandingViewModel(standing)
                this.standings.push(convertedMatch);
                if (this.standings.length === apiStandings.standings.length) {
                    this.loadingStateSubject.next(loadingState.serverError);
                } else {
                    this.loadingStateSubject.next(loadingState.hidden);
                }
            } catch (error) { 
                this.loadingStateSubject.next(loadingState.loaded);
            }
        });
    }
}