import { BrowserRouter, Route, Routes } from 'react-router-dom';

import './Home.scss';

function Home() {
  return (
    <div className="home">
      <div className='menu-items'>
        <a className="menu-item sway-left" href={"/web/events"} target="">
            <label className="glass-h1 menu-item-text">Events List</label>
        </a>
        <a className="menu-item sway-right" href={"/web/create-event"} target="">
            <label className="glass-h1 menu-item-text">Create Event</label>
        </a>
        <a className="menu-item sway-left" href={"/web/prizeout"} target="">
            <label className="glass-h1 menu-item-text">Prize Out</label>
        </a>
        <a className="menu-item sway-right" href={"/web/side-events"} target="">
            <label className="glass-h1 menu-item-text">Side Events</label>
        </a>
        <a className="menu-item sway-left" href={"/web/data-import"} target="">
            <label className="glass-h1 menu-item-text">Data Importer</label>
        </a>
      </div>
    </div>
  );
}

export default Home;