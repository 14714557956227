export enum competitorDivision {
        Junior = 0,
        Senior = 1,
        Master = 2,
}

export function competitorDivisionfromString(value: string): competitorDivision | null {
        switch (value.toLowerCase()) {
                case "junior":
                        return competitorDivision.Junior;
                case "senior":
                        return competitorDivision.Senior;
                case "master":
                        return competitorDivision.Master;
                default:
                        console.log("competitorDivisionfromString: Unknown value: ", value);
                        return null;
        }
}

export namespace competitorDivision {
        export function name(state: competitorDivision): string {
                switch (state) {
                        case competitorDivision.Junior:
                                return "Junior";
                        case competitorDivision.Senior:
                                return "Senior";
                        case competitorDivision.Master:
                                return "Master";
                        default:
                                return "Unknown";
                }
        }
        export function apiComponent(state: competitorDivision): string {
                switch (state) {
                        case competitorDivision.Junior:
                                return "junior";
                        case competitorDivision.Senior:
                                return "senior";
                        case competitorDivision.Master:
                                return "master";
                        default:
                                return "unknown";
                }
        }
}