import { useState, useEffect } from "react";
import GlassCard from "../../components/glassTheme/GlassCard";
import '../../components/glassTheme/Common.scss';
import '../../components/glassTheme/Switch.scss';
import './StreamDataControls.scss';
import React from 'react';
import { streamDataHighlightedMatch, streamDataViewModel } from "./StreamDataControls.ViewModel";
import { BehaviorSubject, Subscription } from "rxjs";
import { FaRegPaperPlane } from 'react-icons/fa';
import { FaRegTrashCan, FaArrowDown } from "react-icons/fa6";
import IconButton, { IconButtonType } from "../../components/IconButton/IconButton";
import { IoPeopleCircleOutline, IoLockClosed } from "react-icons/io5";
import { PiUserSwitchFill } from "react-icons/pi";
import { EditStreamMatchAction, unsavedChangesViewModel } from "../PairingsDashboard/PairingsDashboard.ViewModel";
import { streamMatchViewModel } from "../Match/Match.ViewModel";
import { motion } from "framer-motion";
import { IoHelpCircle } from "react-icons/io5";
import { FaStar } from 'react-icons/fa';
import { FaRankingStar } from "react-icons/fa6";
import { MdCancel } from "react-icons/md";
import UploadTracker, { UploadTrackerIcon } from "../../components/UploadTracker/UploadTracker";
import { streamDataChannel } from "../../Models/Common/StreamDataChannel";
import { QueryParamService } from "../../Services/QueryParamService";

interface StreamDataControlsProps {
    viewModelSubject: BehaviorSubject<streamDataViewModel | null>;
    localStreamDataChannelSubject: BehaviorSubject<streamDataChannel | null>;
    localFeatureMatchSubject: BehaviorSubject<streamMatchViewModel[]>;
    localPlayersToWatchSubject: BehaviorSubject<string[]>;
    localStreamMatchSubject: BehaviorSubject<streamMatchViewModel | null>;
    unsavedChangesSubject: BehaviorSubject<unsavedChangesViewModel>;
    roundNumber: number | null;
    clearStreamMatch: () => void;
    clearFeatureMatches: () => void;
    editStreamDataChannel: (channel: streamDataChannel | null) => void;
    editStreamMatch: (match: streamMatchViewModel | null, action: EditStreamMatchAction) => void;
    editFeatureMatch: (match: streamMatchViewModel | null, action: EditStreamMatchAction) => void;
    clearPlayersToWatch: () => void;
    uploadStreamData: () => void;
    resetLocalChanges: () => void;
}

const StreamDataControls: React.FC<StreamDataControlsProps> = ({
    viewModelSubject,
    clearStreamMatch: clearStreamMatch,
    clearFeatureMatches: clearFeatureMatches,
    editStreamDataChannel: editStreamDataChannel,
    editStreamMatch: editStreamMatch,
    editFeatureMatch: editFeatureMatch,
    clearPlayersToWatch,
    uploadStreamData,
    resetLocalChanges,
    localStreamDataChannelSubject: localStreamDataChannelSubject,
    localFeatureMatchSubject: localFeatureMatchSubject,
    localPlayersToWatchSubject,
    localStreamMatchSubject,
    unsavedChangesSubject })  => {

    QueryParamService.alertOnRefresh();

    const [viewModel, setViewModel] = useState<streamDataViewModel | null>(null);
    const [localStreamDataChannel, setLocalStreamDataChannel] = useState<streamDataChannel | null>(null);
    const [localFeatureMatches, setLocalFeatureMatches] = useState<streamMatchViewModel[] | null>(null);
    const [localPlayersToWatch, setLocalPlayersToWatch] = useState<string[] | null>(null);
    const [localStreamMatch, setLocalStreamMatch] = useState<streamMatchViewModel | null>(null);
    const [unsavedChanges, setUnsavedChanges] = useState<unsavedChangesViewModel>();
    const [highlightedMatchState, setHighlightedMatchState] = useState<streamDataHighlightedMatch | null>(null);
    const [showTooltip, setShowTooltip] = useState<boolean>(false);

    useEffect(() => {
        var subs: Subscription[] = [];
        subs.push(viewModelSubject.subscribe((value) => setViewModel(value)));
        subs.push(localStreamDataChannelSubject.subscribe((value) => setLocalStreamDataChannel(value)));
        subs.push(localFeatureMatchSubject.subscribe((value) => setLocalFeatureMatches([...value ?? []])));
        subs.push(localPlayersToWatchSubject.subscribe((value) => setLocalPlayersToWatch([...value ?? []])));
        subs.push(localStreamMatchSubject.subscribe(value => setLocalStreamMatch(value)));
        subs.push(unsavedChangesSubject.subscribe(value => setUnsavedChanges({...value})));
    }, [viewModelSubject]);

    useEffect(() => {
        var subs: Subscription[] = [];
        if (viewModel) {
            viewModel.editFeatureMatch = editFeatureMatch;
            viewModel.editStreamDataChannel = editStreamDataChannel;
            viewModel.editStreamMatch = editStreamMatch;
            subs.push(viewModel?.highlightedMatch.subscribe(value => setHighlightedMatchState(value)));
        }
        return () => subs.forEach(s => s.unsubscribe());
    }, [viewModel]);

    useEffect(() => {
        if (viewModel?.deletedFeatureMatchIndex != null) {
            let match = localFeatureMatchSubject.getValue()[viewModel.deletedFeatureMatchIndex];
            if (!match) {
                match = localFeatureMatchSubject.getValue()[viewModel.deletedFeatureMatchIndex-1];
            }
            if (match) {
                viewModel.toggleHighlightedMatch(match, false, true);
            }
            viewModel.deletedFeatureMatchIndex = null;
        }
    }, [localFeatureMatches]);

    return (
        <div className="stream-data-controls">
        <motion.div className="stream-data-controls-card">
            <GlassCard>
                <div className={`streamDataControlsTooltip${showTooltip ? '' : 'Hidden'}`}>
                    <div className="streamDataControlsTooltipContent">
                        <label className="streamDataControlsTooltipContentDefinition">Controls for configuring data on the /stream-feed API endpoint. Refresh required to update rounds.</label>
                    </div>
                    <div className="streamDataControlsTooltipContent">
                        <IoPeopleCircleOutline className="streamDataControlsTooltipContentIcon"/>
                        <div className="streamDataControlsTooltipContentDefinitionPair">
                            <label className="streamDataControlsTooltipContentTitle">Stream Match</label>
                            <label className="streamDataControlsTooltipContentDefinition">Select zero or one.</label>
                        </div>
                    </div>
                    <div className="streamDataControlsTooltipContent">
                        <FaRankingStar className="streamDataControlsTooltipContentIcon"/>
                        <div className="streamDataControlsTooltipContentDefinitionPair">
                            <label className="streamDataControlsTooltipContentTitle">Feature Match</label>
                            <label className="streamDataControlsTooltipContentDefinition">Select zero, one, or many (ordered).</label>
                        </div>
                    </div>
                    <div className="streamDataControlsTooltipContent">
                        <FaStar className="streamDataControlsTooltipContentIcon"/>
                        <div className="streamDataControlsTooltipContentDefinitionPair">
                            <label className="streamDataControlsTooltipContentTitle">Player to Watch</label>
                            <label className="streamDataControlsTooltipContentDefinition">A flag which persists between rounds.</label>
                        </div>
                    </div>
                    <div className="streamDataControlsTooltipContent">
                        <PiUserSwitchFill className="streamDataControlsTooltipContentIcon"/>
                        <div className="streamDataControlsTooltipContentDefinitionPair">
                            <label className="streamDataControlsTooltipContentTitle">Players Switched</label>
                            <label className="streamDataControlsTooltipContentDefinition">P1 and P2 data will be switched on the API endpoint.</label>
                        </div>
                    </div>
                </div>

                <div className="stream-data-controls">
                    <div className="stream-data-header">
                        <div className="channel-select-controls">
                            {unsavedChanges?.isStreamDataChannelChanged && (
                                <label className="channel-select-subtitle bold italic">Currently { viewModel?.streamDataChannel ? streamDataChannel.name(viewModel?.streamDataChannel!) : "no channel"}</label>
                            )}
                            {!unsavedChanges?.isStreamDataChannelChanged && (
                                <label className="channel-select-subtitle italic">API Feed endpoint:</label>
                            )}
                            <select
                                className="channel-select-select"
                                value={localStreamDataChannel !== null ? localStreamDataChannel.toString() : ""}
                                onChange={(e) => {
                                    const selectedValue = parseInt(e.target.value);
                                    viewModel?.streamDataChannelChange(selectedValue as streamDataChannel);
                                }}>
                                {Object.keys(streamDataChannel)
                                    .filter((key) => !isNaN(Number(key)))
                                    .map((key) => {
                                        const value = Number(key) as streamDataChannel;
                                        return (
                                            <option key={key} value={value}>
                                                {streamDataChannel.name(value)}
                                            </option>
                                        );
                                    })}
                            </select>
                        </div>
                        <label className="glass-title">Stream Data</label>
                        <div className="channel-select-controls"/>
                    </div>
                    {showTooltip ?
                        <MdCancel className="streamDataControlsTooltipIcon" onClick={() => setShowTooltip(!showTooltip)}/> :
                        <IoHelpCircle className="streamDataControlsTooltipIcon" onClick={() => setShowTooltip(!showTooltip)}/>
                    }
                    <div className="matchEditorControls">
                        {/* Read only */}
                        <div className="matchEditorContainer">
                            <div className="matchEditorHeader">
                                <label className="matchEditorSubtitle flexBookendLeft">Data on server</label>
                                <label className="matchEditorSubtitle flexBookendRight"><FaStar className="matchEditorSubtitleIcon"/><i>{viewModel?.playersToWatch.length} players to watch</i></label>
                            </div>
                            <div className="matchEditorReadOnly">
                                <div className="matchEditorRail">
                                    {!viewModel && (
                                        <div className="matchCardLoading">
                                            <label className="matchCardTitle"><i>Loading</i></label>
                                        </div>
                                    )}
                                    {viewModel?.streamMatch && (
                                        <div className="matchCard">
                                            <IoPeopleCircleOutline className="matchCardOverlayIcon"/>
                                            {viewModel?.streamMatch.shouldSwitchPlayers && <PiUserSwitchFill className="matchCardOverlayIconRight"/>}
                                            <label className="matchCardTitle">{viewModel?.streamMatch.tableNumber}</label>
                                            <label className="matchCardSubtitle">Round {viewModel?.streamMatch.roundNumber}</label>
                                        </div>
                                    )}
                                    {viewModel && !viewModel?.streamMatch && (
                                        <div className="matchCardPlaceholder">
                                            <IoPeopleCircleOutline className="matchCardOverlayIcon"/>
                                            <label className="matchCardTitle"><i>None</i></label>
                                        </div>
                                    )}
                                    <div className="matchEditorRailDivider" hidden={!viewModel}/>
                                    {viewModel?.featureMatches && viewModel?.featureMatches.length === 0 && (
                                        <div className="matchCardPlaceholder">
                                            <FaRankingStar className="matchCardOverlayIcon"/>
                                            <label className="matchCardTitle"><i>None</i></label>
                                        </div>
                                    )}
                                    {viewModel?.featureMatches?.map((match, index) => (
                                        <div key={index} className="matchCard">
                                            <FaRankingStar className="matchCardOverlayIcon"/>
                                            {match.shouldSwitchPlayers && <PiUserSwitchFill className="matchCardOverlayIconRight"/>}
                                            <label className="matchCardTitle">{match.tableNumber}</label>
                                            <label className="matchCardSubtitle">Round {match.roundNumber}</label>
                                        </div>
                                    ))}
                                </div>
                                <IoLockClosed className="matchEditorOverlayIcon"/>
                            </div>
                        </div>
                        {/* Editable */}
                        <div className="matchEditorContainer">
                            <div className="matchEditorHeader">
                                <label className="matchEditorTitle flexBookendLeft">{unsavedChanges?.isAnyChanges ? "Unsaved match changes" : "No changes detected"}</label>
                                <FaArrowDown className="unsavedChangesIndicator"/>
                                <label className="matchEditorTitle flexBookendRight"><i>{unsavedChanges?.isPlayersToWatchChanged && localPlayersToWatch?.length + " players to watch" || "Players to watch unchanged"}</i></label>
                            </div>
                            <div className="matchEditor">
                                <div className="matchEditorRail">
                                    {!viewModel && (
                                        <div className="matchCardLoading">
                                            <label className="matchCardTitle"><i>Loading</i></label>
                                        </div>
                                    )}
                                    {viewModel && localStreamMatch && (
                                        <div className={`matchCardSelectable${localStreamMatch === highlightedMatchState?.data ? 'Selected' : ''}`} onClick={() => viewModel?.toggleHighlightedMatch(localStreamMatch, true, false)}>
                                            <IoPeopleCircleOutline className="matchCardOverlayIcon"/>
                                            {localStreamMatch.shouldSwitchPlayers && <PiUserSwitchFill className="matchCardOverlayIconRight"/>}
                                            <label className="matchCardTitle">{localStreamMatch.tableNumber}</label>
                                            <label className="matchCardSubtitle">Round {localStreamMatch.roundNumber}</label>
                                        </div>
                                    )}
                                    {viewModel && !localStreamMatch && (
                                        <div className="matchCardPlaceholder">
                                            <IoPeopleCircleOutline className="matchCardOverlayIcon"/>
                                            <label className="matchCardTitle"><i>None</i></label>
                                        </div>
                                    )}
                                    <div className="matchEditorRailDivider" hidden={!viewModel}/>
                                    {viewModel && localFeatureMatches?.map((match, index) => (
                                        <div key={index} className={`matchCardSelectable${match === highlightedMatchState?.data ? 'Selected' : ''}`} onClick={() => viewModel?.toggleHighlightedMatch(match, false, true)}>
                                            <FaRankingStar className="matchCardOverlayIcon"/>
                                            {match.shouldSwitchPlayers && <PiUserSwitchFill className="matchCardOverlayIconRight"/>}
                                            <label className="matchCardTitle">{match.tableNumber}</label>
                                            <label className="matchCardSubtitle">Round {match.roundNumber}</label>
                                        </div>
                                    ))}
                                    {viewModel && localFeatureMatches?.length === 0 && (
                                        <div className="matchCardPlaceholder">
                                            <FaRankingStar className="matchCardOverlayIcon"/>
                                            <label className="matchCardTitle"><i>None</i></label>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="matchEditorFooter">
                                <div className="flexBookendLeft">
                                    <button className="footerButton" onClick={() => {clearStreamMatch(); clearFeatureMatches(); clearPlayersToWatch();}}><FaRegTrashCan className="glass-button-icon-left"/>Delete all data</button>
                                </div>
                                <div className="railOrderControls">
                                    <IconButton isEnabled={true} hasShadow={false} onPress={() => viewModel?.editHighlightedMatch(EditStreamMatchAction.Promote)} iconType={IconButtonType.ArrowLeft} label="Promote highlighted match higher in the list" showLabelAbove={true}/>
                                    <IconButton isEnabled={true} hasShadow={false} onPress={() => viewModel?.editHighlightedMatch(EditStreamMatchAction.ToggleSwitchPlayers)} iconType={IconButtonType.SwitchPlayers} label="Switch player positions on highlighted match" showLabelAbove={true}/>
                                    <IconButton isEnabled={true} hasShadow={false} onPress={() => viewModel?.editHighlightedMatch(EditStreamMatchAction.Delete)} iconType={IconButtonType.Trash} label="Remove highlighted match from the list" showLabelAbove={true}/>
                                    <IconButton isEnabled={true} hasShadow={false} onPress={() => viewModel?.editHighlightedMatch(EditStreamMatchAction.Demote)} iconType={IconButtonType.ArrowRight} label="Demote highlighted match lower in the list" showLabelAbove={true}/>
                                </div>
                                <div className="flexBookendRight">
                                    <button className="footerButton" onClick={() => uploadStreamData()}>Save Changes<FaRegPaperPlane className="glass-button-icon-right"/></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </GlassCard>
            </motion.div>
            <div className="card-footer-right">
            { viewModel &&
                <UploadTracker
                    isUploading={viewModel.isUploading}
                    uploadResult={viewModel?.uploadResult}
                    defaultIcon={UploadTrackerIcon.StreamData}
                    defaultTitle={viewModel.lastUpdated ? viewModel.lastUpdated : "Upload info will appear here"}
                    defaultSubtitle={viewModel.lastUpdated ? "Last stream data edit" : ""}
                    uploadMessage="Uploading stream data..."/>
            }
            </div>
        </div>
    );
}

export default StreamDataControls;