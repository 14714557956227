import { useState, useEffect, Fragment } from "react";
import '../../components/glassTheme/Common.scss';
import '../../components/glassTheme/Switch.scss';
import './StandingsList.scss';
import React from 'react';
import { BehaviorSubject, Subscription } from "rxjs";
import Loader, { SpinnerType } from "../../components/Spinner/Loader";
import SearchBar from "../../components/search-bar/SearchBar";
import { GiKiwiBird } from "react-icons/gi";
import FilterButton, { FilterButtonType } from "../../components/filter-button/FilterButton";
import { StandingViewModel } from "../Standing/Standing.ViewModel";
import { StandingsListViewModel } from "./StandingsList.ViewModel";
import Standing from "../Standing/Standing";
import { loadingState } from "../../Models/Common/LoadingState";

interface StandingsSubviewProps {
    viewModelSubject: BehaviorSubject<StandingsListViewModel | null>;
    highlightedStanding: BehaviorSubject<StandingViewModel | null>;
    toggleHighlightedStanding: (match: StandingViewModel) => void;
}

enum StandingsFilterType {
    Dropped,
    Search,
    Default,
}

const StandingsList: React.FC<StandingsSubviewProps> = ({ viewModelSubject, highlightedStanding, toggleHighlightedStanding }) => {
    const [standings, setStandings] = useState<StandingViewModel[]>([]);
    const [highlightedStandingState, setHighlightedStandingState] = useState<StandingViewModel | null>(null);
    const [StandingsFilter, setStandingsFilter] = useState<StandingsFilterType>(StandingsFilterType.Default);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [loadingState, setLoadingState] = useState<BehaviorSubject<loadingState> | undefined>();

    useEffect(() => {
            var subs: Subscription[] = [];
            subs.push(viewModelSubject.subscribe((viewModel) => {
                setLoadingState(viewModel?.loadingStateSubject);
                setStandings([...viewModel?.standings ?? []])}));
            return () => subs.forEach(s => s.unsubscribe());
    }, [viewModelSubject]);

    if (standings === null) {
        return <Loader showMessage={true} spinnerType={SpinnerType.FullPage} loadingStateSubject={loadingState} />;
    }

    const filteredStandings = standings.filter(standing => {
        switch (StandingsFilter) {
            case StandingsFilterType.Dropped:
                return standing.isDropped;
            case StandingsFilterType.Search:
                if (searchTerm === "") {
                    return true;
                }
                return standing.fullName().toLowerCase().includes(searchTerm.toLowerCase())
            default:
                return true;
        }
    });

    const StandingsFilterValueChange = (value: StandingsFilterType) => {
        if (StandingsFilter === value) {
            setStandingsFilter(StandingsFilterType.Default);
            return;
        }
        setStandingsFilter(value);
        setSearchTerm("");
    }

    const searchTermValueChange = (value: string) => {
        setStandingsFilter(StandingsFilterType.Search);
        setSearchTerm(value);
    }

    return (
        <div className="standings-list">
        <Fragment key={filteredStandings.length}>
            <ul className="standings-list-items">
                {filteredStandings.map(standing => (
                    <li className="standings-list-item" key={standing.playerId}>
                        <Standing
                            viewModel={standing}
                            isHighlightedStanding={highlightedStandingState === standing}
                            toggleHighlightedStanding={() => {
                                setHighlightedStandingState(standing);
                                toggleHighlightedStanding(standing);
                            }}
                        />
                    </li>
                ))}
                {filteredStandings.length === 0 && <div className="searchError">
                    <label className="glass-h2"><i>No standings found for current filters.</i></label>
                    <GiKiwiBird className="searchErrorIcon"/>
                        </div>}
            </ul>
        </Fragment>
        <div className="filterControls">
            <FilterButton
                isToggled={StandingsFilter === StandingsFilterType.Dropped}
                onToggle={() => StandingsFilterValueChange(StandingsFilterType.Dropped)}
                iconType={FilterButtonType.ToWatch}/>
            <SearchBar
                searchTerm={searchTerm}
                onSearch={searchTermValueChange}/>
        </div>
    </div>
    );
}

export default StandingsList;