import { useEffect, useState } from "react";
import "./Navbar.scss"
import React from "react";
import { CoreValuesService } from "../../Services/CoreValuesService";
import { NavbarData, NavbarProperties, navbarViewModel } from "./Navbar.ViewModel";
import HamburgerMenu from "../hamburger-menu/HamburgerMenu";
import NavbarSyncTool from "./NavbarSyncTool/NavbarSyncTool";
import NavbarSignInTool from "./NavbarSignInTool/NavbarSignInTool";

interface NavbarProps {
    properties: NavbarProperties;
}

const Navbar: React.FC<NavbarProps> = ({ properties }) => {
    const [viewModel, setViewModel] = useState<navbarViewModel>(new navbarViewModel(new NavbarData()));

    useEffect(() => {
        properties.data.subscribe((data) => {
            var vm = new navbarViewModel(data);
            setViewModel(vm)
        });
    }, [properties]);

    let versionNumber = CoreValuesService.appVersion;
    let env = process.env.REACT_APP_ENVIRONMENT ?? "";

    return(
        <nav className="navbar-items">
            <div className="navbarBookend-start">
                <a href={"/"} target="">
                    <HamburgerMenu/>
                </a>
                <NavbarSignInTool/>
            </div>
            <div className="titleContainer">
                <label className="nav-title"><b>{viewModel.title}</b></label>
                <div className="subtitleContainer">
                <label className="nav-subtitle"><b>{viewModel.subtitle}</b></label>
                <label className={getEnvironmentStyle(env)}>{getEnvironmentName(env)}</label>
                <label className="nav-version"><b>v{versionNumber}</b></label>
                </div>
            </div>
            <div className="navbarBookend-end">
                <NavbarSyncTool
                    config={properties.syncToolConfig}/>
            </div>
        </nav>
    )
}

function getEnvironmentName(environment: string) {
    switch(environment) {
        case "local":
            return "Local";
        case "dev":
            return "Develop";
        case "test":
            return "Test";
        case "prod":
            return "Live";
        default:
            return "Unknown";
    }
}

function getEnvironmentStyle(environment: string) {
    switch(environment) {
        case "local":
            return "env-local";
        case "dev":
            return "env-dev";
        case "test":
            return "env-test";
        case "prod":
            return "env-prod";
        default:
            return "env-unknown";
    }
}

export default Navbar;