import { BehaviorSubject, combineLatest } from "rxjs";
import { ApiConnectService } from "../../Services/ApiConnectService";
import { TournamentDataService } from "../../Services/TournamentDataService";
import { NavbarData } from "../../components/navbar/Navbar.ViewModel";
import { eventType } from "../../Models/Common/EventType";
import { eventSeason } from "../../Models/Common/EventSeason";
import { gameType } from "../../Models/Common/GameType";
import { RequestResult } from "../../Models/Common/RequestResult";
import { createEventTransfer } from "../../Models/Inbound/CreateEventTransfer";
import { eventViewModel } from "../EventsDashboard/EventsDashboard.ViewModel";
import { loadingState } from "../../Models/Common/LoadingState";
import { PrizeDefinitionConfiguratorViewModel } from "../PrizeDefinitionConfigurator/PrizeDefinitionConfigurator.ViewModel";
import { PrizeDefinitionConfiguratorDivision } from "../PrizeDefinitionConfigurator/PrizeDefinitionConfiguratorDivision";
import { apiPrizeoutDefinitionTransfer } from "../../Models/Prizeout/ApiPrizeoutDefinitionTransfer";

export enum CreateEventValidationError {
  eventGroupIdError,
  eventLocationError,
  eventTypeError,
  eventSeasonError,
  gameTypeError,
  creationError,
  otherError,
  initialState,
}

export class CreateEventViewModel {
    navbarData: BehaviorSubject<NavbarData>;
    isEditMode: boolean;
    editModeEventId: string;
    uploadResult = new BehaviorSubject<RequestResult<any> | null>(null);
    isUploading = new BehaviorSubject<boolean>(false);
    validationError = new BehaviorSubject<CreateEventValidationError | null>(CreateEventValidationError.initialState);
    eventDataSubject = new BehaviorSubject<eventViewModel | null>(null);
    prizeDefinitionSubject = new BehaviorSubject<PrizeDefinitionConfiguratorViewModel[]>([]);
    loadingStateSubject = new BehaviorSubject<loadingState>(loadingState.loading);

    acs = new ApiConnectService();
    tournamentService = new TournamentDataService(this.acs);

    constructor(
      navbarDataSubject: BehaviorSubject<NavbarData>,
      isEditMode: boolean,
      editModeEventId: string
    ) {
        this.navbarData = navbarDataSubject;
        this.isEditMode = isEditMode;
        this.editModeEventId = editModeEventId;
        if (isEditMode) {
          this.refreshEditEventData();
          this.loadingStateSubject.next(loadingState.loading);
        } else {
          this.loadingStateSubject.next(loadingState.hidden);
        }
        
    this.prizeDefinitionSubject.next([new PrizeDefinitionConfiguratorViewModel(eventType.SideEvent, PrizeDefinitionConfiguratorDivision.AllDivisions, null)]);
    }

    validateEventDetails = (
      eventGroupId: string,
      location: string,
      eventType: eventType,
      eventSeason: eventSeason,
      gameType: gameType) =>
    {
      if (!this.validateEventGroupId(eventGroupId)) { this.validationError.next(CreateEventValidationError.eventGroupIdError); return; };
      if (!location || !this.validateEventLocation(location)) { this.validationError.next(CreateEventValidationError.eventLocationError); return; };
      if (eventType === null) { this.validationError.next(CreateEventValidationError.eventTypeError); return; };
      if (eventSeason === null) { this.validationError.next(CreateEventValidationError.eventSeasonError); return; };
      if (gameType === null) { this.validationError.next(CreateEventValidationError.gameTypeError); return; };
      this.validationError.next(null);
    }

    refreshEditEventData = () => {
      this.tournamentService.GetEventById(this.editModeEventId).subscribe(apiData => {
      if (apiData.result) {
          var eventData = eventViewModel.fromApiEvent(apiData.result);
          this.eventDataSubject.next(eventData ?? null);
          if (eventData?.prizeoutDefinitions && eventData.prizeoutDefinitions.length > 0) {
            console.log("Prize definitions found: " + eventData.prizeoutDefinitions.length);
            const prizeDefinitions = eventData.prizeoutDefinitions.map(def => new PrizeDefinitionConfiguratorViewModel(eventData.details.type, PrizeDefinitionConfiguratorDivision.fromCompetitorDivision(def.division), def));
            this.prizeDefinitionSubject.next(prizeDefinitions);
          }
          setTimeout(() => {
              this.loadingStateSubject.next(loadingState.hidden);
          });
        } else {
          this.loadingStateSubject.next(loadingState.error);
        }
      });
    }

    requestCreateEvent = (
      eventGroupId: string,
      location: string,
      eventType: eventType,
      eventSeason: eventSeason,
      gameType: gameType,
      calculateStreamStandingsOnUpload: boolean,
      calculatePrizeoutStandingsOnUpload: boolean,
      isPrizingEnabled: boolean) => {

      var createEventTransfer: createEventTransfer = {
        eventGroupId: eventGroupId,
        eventDetails: {
          location: location,
          type: eventType,
          season: eventSeason,
          gameType: gameType
        },
        eventConfig: {
          calculateStreamStandingsOnUpload: calculateStreamStandingsOnUpload,
          calculatePrizeoutStandingsOnUpload: calculatePrizeoutStandingsOnUpload,
          isPrizeoutEnabled: isPrizingEnabled
        },
        prizeoutDefinitions: ([] as apiPrizeoutDefinitionTransfer[]).concat(...this.prizeDefinitionSubject.getValue().map(prizeDef => prizeDef.ToApiPrizeoutDefinitionTransfer()))
      };
      
      if (this.isEditMode) {
        console.log("Requesting edit of event with ID: " + this.editModeEventId);
        this.isUploading.next(true);
        this.tournamentService.EditEvent(this.editModeEventId, createEventTransfer).subscribe(result => {
          if (!(result && result.result?.eventId === this.editModeEventId)) {
            this.validationError.next(CreateEventValidationError.creationError);
          }
          this.uploadResult.next(null);
          this.uploadResult.next(result);
          this.isUploading.next(false);
        });
      } else {
        console.log("Requesting creation of event with location: " + location);
        this.isUploading.next(true);
        this.tournamentService.CreateEvent(createEventTransfer).subscribe(result => {
          if (!(result && result.result?.eventId)) {
            this.validationError.next(CreateEventValidationError.creationError);
          }
          this.uploadResult.next(null);
          this.uploadResult.next(result);
          this.isUploading.next(false);
        });
      }
    }

    updatePrizeDefinitionViewModel = (prizeDef: PrizeDefinitionConfiguratorViewModel) => {
      var prizeDefinitions = this.prizeDefinitionSubject.getValue();
      var index = prizeDefinitions.findIndex(def => def.division === prizeDef.division);
      if (index >= 0) {
        prizeDefinitions[index] = prizeDef;
      } else {
        prizeDefinitions.push(prizeDef);
      }
      this.prizeDefinitionSubject.next(prizeDefinitions);
      console.log("Prize definition updated with " + prizeDefinitions.length + " definitions");
    }
  
    private validateEventGroupId (eventGroupId: string): boolean {
      const regex = /^$|^[^\s]{0,25}$/;
      return regex.test(eventGroupId);
    }

    private validateEventLocation(location: string): boolean {
      const regex = /^[a-zA-Z0-9\s\-\.,'()!@#$%^&*]{1,50}$/;
      return regex.test(location);
    }
}