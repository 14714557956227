import React, { useEffect, useState } from 'react';
import "./PrizeoutButton.scss"
import { FaSync, FaTrophy } from 'react-icons/fa';
import { PrizeoutFulfilmentViewModel } from '../PrizeoutStanding/PrizeoutStanding.ViewModel';
import { prizeoutPrizeType } from '../../Models/Common/PrizeoutPrizeType';
import { GiRolledCloth } from "react-icons/gi";
import { BiSolidMedal } from "react-icons/bi";
import { TbPlayCardStarFilled, TbCardsFilled } from "react-icons/tb";
import { GiCubes } from "react-icons/gi";
import { IoTicket } from "react-icons/io5";
import { BsFillBackpackFill } from "react-icons/bs";

interface PrizeoutButtonProps {
    fulfilment: PrizeoutFulfilmentViewModel;
    hoverText: string | null;
    onToggleEdit: (prizeGuid: string, updatedValue: number | null) => void;
}

const PrizeoutButton: React.FC<PrizeoutButtonProps> = ({fulfilment, hoverText, onToggleEdit}) => {
    const [toggledState, setToggledState] = useState<boolean>(false);
    const [isSyncingChange, setIsSyncingChange] = useState<boolean>(false);

    useEffect(() => {
        setToggledState(fulfilment.localQuantityReceived === null ? fulfilment.remoteQuantityReceived === fulfilment.quantityEarned : fulfilment.localQuantityReceived === fulfilment.quantityEarned);
        setIsSyncingChange(fulfilment.localQuantityReceived !== fulfilment.remoteQuantityReceived && fulfilment.localQuantityReceived !== null)
    }, [fulfilment, onToggleEdit]);


    const onToggle = () => {
        // No local, no server; set to due
        if (fulfilment.localQuantityReceived === null && (fulfilment.remoteQuantityReceived === null || fulfilment.remoteQuantityReceived === 0)) {
            console.log("No local, no server; set to due");
            onToggleEdit(fulfilment.prizeGuid, fulfilment.quantityEarned);
        // No local, server value; set to zero
        } else if (fulfilment.localQuantityReceived === null && fulfilment.remoteQuantityReceived !== null) {
            console.log("No local, server value; set to zero");
            onToggleEdit(fulfilment.prizeGuid, 0);
        // Local is zero, server value; set to due (next click sets to zero)
        } else if (fulfilment.localQuantityReceived === 0 && fulfilment.remoteQuantityReceived !== null) {
            console.log("Local is zero, server value; set to due (next click sets to zero)");
            onToggleEdit(fulfilment.prizeGuid, fulfilment.quantityEarned);
        // Local value, no server; reset local
        } else if (fulfilment.localQuantityReceived !== null && fulfilment.remoteQuantityReceived === null) {
            console.log("Local value, no server; reset local");
            onToggleEdit(fulfilment.prizeGuid, null);
        // Local value, server value; set to zero (next click sets to due)
        } else if (fulfilment.localQuantityReceived !== null && fulfilment.remoteQuantityReceived !== null) {
            console.log("Local value, server value; set to zero (next click sets to due)");
            onToggleEdit(fulfilment.prizeGuid, 0);
        }
    }

    let icon = <GiCubes />;

    switch (fulfilment.type) {
        case prizeoutPrizeType.Bag:
            icon = toggledState ? <BsFillBackpackFill className='prizeoutButton-icon prizeoutButton-selected'/> : <BsFillBackpackFill className='prizeoutButton-icon prizeoutButton-deselected'/>;
            break;
        case prizeoutPrizeType.Booster:
            icon = toggledState ? <TbCardsFilled className='prizeoutButton-icon prizeoutButton-selected'/> : <TbCardsFilled className='prizeoutButton-icon prizeoutButton-deselected'/>;
            break;
        case prizeoutPrizeType.Medal:
            icon = toggledState ? <BiSolidMedal className='prizeoutButton-icon prizeoutButton-selected'/> : <BiSolidMedal className='prizeoutButton-icon prizeoutButton-deselected'/>;
            break;
        case prizeoutPrizeType.Playmat:
            icon = toggledState ? <GiRolledCloth className='prizeoutButton-icon prizeoutButton-selected'/> : <GiRolledCloth className='prizeoutButton-icon prizeoutButton-deselected'/>;
            break;
        case prizeoutPrizeType.Promo:
            icon = toggledState ? <TbPlayCardStarFilled className='prizeoutButton-icon prizeoutButton-selected'/> : <TbPlayCardStarFilled className='prizeoutButton-icon prizeoutButton-deselected'/>;
            break;
        case prizeoutPrizeType.PrizePoints:
            icon = toggledState ? <IoTicket className='prizeoutButton-icon prizeoutButton-selected'/> : <IoTicket className='prizeoutButton-icon prizeoutButton-deselected'/>;
            break;
        case prizeoutPrizeType.Trophy:
            icon = toggledState ? <FaTrophy className='prizeoutButton-icon prizeoutButton-selected'/> : <FaTrophy className='prizeoutButton-icon prizeoutButton-deselected'/>;
            break;
        case prizeoutPrizeType.Other:
            icon = toggledState ? <GiCubes className='prizeoutButton-icon prizeoutButton-selected'/> : <GiCubes className='prizeoutButton-icon prizeoutButton-deselected'/>;
            break;       
    }

    return (
        <div className={`prizeoutButton-button ${toggledState ? 'paidOut' : ''}`} onClick={(e) => { e.stopPropagation(); onToggle(); }}>
            <div className='prizeoutButton-label-container'>
                <label className='prizeoutButton-HoverLabel'>{fulfilment.quantityReceivedReadable} / {fulfilment.quantityEarnedReadable} awarded</label>
            </div>
            <label className='prizeoutButton-HeaderLabel-prefix'>
                {fulfilment.quantityReceivedReadable !== fulfilment.quantityEarnedReadable && fulfilment.quantityReceivedReadable !== "0"
                    && `${fulfilment.quantityReceivedReadable} given`}
            </label>
            <div className='prizeoutButton-headerRow'>
                <label className='prizeoutButton-HeaderLabel'>
                    {(fulfilment.quantityEarnedReadable !== "1" && `${fulfilment.quantityEarnedReadable}`)}
                </label>
                {icon}
            </div>
            <label className='glass-caption bold'>{fulfilment.ReadableTypeWithDifferentiator()}</label>
            {isSyncingChange && <div className='prizeoutButton-corner-element'><FaSync className='sync-badge-icon'/></div>}
        </div>
    );
};

export default PrizeoutButton;