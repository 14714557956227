import './ErrorPage.scss';
import { GiKiwiBird } from "react-icons/gi";
import React from 'react';

interface ErrorPageProps {
  errorType: ErrorType;
}

export enum ErrorType {
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  InternalServerError = 500,
}

const ErrorPage: React.FC<ErrorPageProps> = ({
  errorType,
}) => {
  const renderErrorIcon = () => {
    switch (errorType) {
      case ErrorType.BadRequest:
        return <GiKiwiBird className='errorPage-icon' />;
      case ErrorType.Unauthorized:
        return <GiKiwiBird className='errorPage-icon' />;
      case ErrorType.Forbidden:
        return <GiKiwiBird className='errorPage-icon' />;
      case ErrorType.NotFound:
        return <GiKiwiBird className='errorPage-icon' />;
      case ErrorType.InternalServerError:
        return <GiKiwiBird className='errorPage-icon' />;
      default:
        return null;
    }
  }

  const errorIconLabel = () => {
    switch (errorType) {
      case ErrorType.BadRequest:
        return '400';
      case ErrorType.Unauthorized:
        return '401';
      case ErrorType.Forbidden:
        return '403';
      case ErrorType.NotFound:
        return '404';
      case ErrorType.InternalServerError:
        return '500';
      default:
        return '';
    }
  }

  const errorTitle = () => {
    switch (errorType) {
      case ErrorType.BadRequest:
        return 'Bad Request';
      case ErrorType.Unauthorized:
        return 'Unauthorized';
      case ErrorType.Forbidden:
        return 'Forbidden';
      case ErrorType.NotFound:
        return 'Page Not Found';
      case ErrorType.InternalServerError:
        return 'Internal Server Error';
      default:
        return '';
    }
  }

  const errorSubtitle = () => {
    switch (errorType) {
      case ErrorType.BadRequest:
        return 'The request could not be understood by the server due to malformed syntax.';
      case ErrorType.Unauthorized:
        return 'Pre-approved account required. Please sign in and try again.';
      case ErrorType.Forbidden:
        return 'You do not have permission to access this resource.';
      case ErrorType.NotFound:
        return 'Please check the URL and contact an admin if something seems wrong.';
      case ErrorType.InternalServerError:
        return 'The server encountered an internal error. Please try again later.';
      default:
        return '';
    }
  }

  const renderErrorContent = () => {
    return (
      <div className="errorPage-content">
        <div className='errorPage-iconCard'>
          {renderErrorIcon()}
            <label className='errorPage-iconLabel'>{errorIconLabel()}</label>
        </div>
        {errorTitle() && <label className='errorPage-title'>{errorTitle()}</label>}
        {errorSubtitle() && <label className='errorPage-subtitle'>{errorSubtitle()}</label>}
      </div>
    );
  };

  const login = () => {
    window.location.href = "/.auth/login/aad";
  };

  return (
    <div className="errorPage">
      {renderErrorContent()}
      {errorType === ErrorType.Unauthorized && (
        <button className='errorPage-button' onClick={() => login()}>Sign In</button>
      )}
      <button className='errorPage-button' onClick={() => window.history.back()}>Previous Page</button>
      <button className='errorPage-button' onClick={() => window.location.href = '/'}>Home</button>
    </div>
  );
}
export default ErrorPage;