import { BehaviorSubject, combineLatest } from "rxjs";
import { csvTransfer, CsvTransferIntent } from "../Models/Inbound/CsvTransfer";
import { TournamentDataService } from "../Services/TournamentDataService";
import { ApiConnectService } from "../Services/ApiConnectService";
import { NavbarData } from "../components/navbar/Navbar.ViewModel";
import { RequestResult } from "../Models/Common/RequestResult";
import { dataImportType } from "../Models/Common/DataImportType";

export enum DataImportValidationError {
  eventIdError,
  csvFileError,
  importError,
  otherError,
  initialState,
}

export class DataImportViewModel {
    navbarData: BehaviorSubject<NavbarData>;
    uploadResult = new BehaviorSubject<RequestResult<any> | null>(null);
    isUploading = new BehaviorSubject<boolean>(false);
    validationError = new BehaviorSubject<DataImportValidationError | null>(null);

    acs = new ApiConnectService();
    tournamentService = new TournamentDataService(this.acs);

    constructor(
      navbarDataSubject: BehaviorSubject<NavbarData>,
    ) {
        this.navbarData = navbarDataSubject;
    }
  
    requestImport = (
      eventId: string,
      csvFile: string | null,
      importType: dataImportType) => {
      if (!eventId) {
        this.validationError.next(DataImportValidationError.eventIdError);
        return;
      }
      if (!csvFile || !csvFile.length) {
        this.validationError.next(DataImportValidationError.csvFileError);
        return;
      }
      var csvTransfer: csvTransfer = {
        csvFile: csvFile,
        intent: this.dataImportTypeToCsvTransferIntent(importType),
        associatedEventId: eventId,
      };
      console.log("Requesting import of VG team lists on event: " + eventId);
      this.isUploading.next(true);
      this.tournamentService.postImportVideoGameTeamsCsv(csvTransfer).subscribe(result => {
        if (!(result && result.result?.eventId === eventId)) {
          this.validationError.next(DataImportValidationError.importError);
        }
        this.uploadResult.next(null);
        this.uploadResult.next(result);
        this.isUploading.next(false);
      });
    }
  
    dataImportTypeToCsvTransferIntent = (
      importType: dataImportType) =>
    {
      switch (importType) {
      case dataImportType.VgTeamListCsv:
        return CsvTransferIntent.ImportVideoGameTeams;
      }
    }
}