import { ApiConnectService } from './ApiConnectService';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { apiResponse } from '../Models/Common/ApiResponse';
import { AxiosResponse } from 'axios';
import { toolkitResult } from '../Models/Outbound/ToolkitResult';
import { tdfTransfer } from '../Models/Inbound/TdfTransfer';
import { standingsReportTransfer } from '../Models/Inbound/StandingsReportTransfer';
import { apiStandings } from '../Models/Inbound/ApiStandings';
import { roundPairingWrapper } from '../Models/Outbound/RoundPairingWrapper';
import { streamDataResponse } from '../Models/Outbound/StreamDataResponse';
import { streamDataTransfer } from '../Models/Inbound/StreamDataTransfer';
import { allEventsData } from '../Models/Outbound/AllEventsData';
import { eventData } from '../Models/Outbound/EventData';
import { eventState } from '../Models/Common/EventState';
import { tdfUploadConfirmation } from '../Models/Outbound/TdfUploadConfirmation';
import { RequestResult } from '../Models/Common/RequestResult';
import { tdfFinalTransfer } from '../Models/Inbound/TdfFinalTransfer';
import { createEventTransfer } from '../Models/Inbound/CreateEventTransfer';
import { eventStateTransfer } from '../Models/Inbound/EventStateTransfer';
import { exportedTdf } from '../Models/Outbound/ExportedTdf';
import { csvTransfer } from '../Models/Inbound/CsvTransfer';
import { LibraryEvent } from '../Models/Library/LibraryEvent';
import { apiPrizeoutStandingsEvent } from '../Models/Inbound/ApiPrizeoutStandingsEvent';
import { BehaviorSubject, interval, Subscription } from 'rxjs';
import { switchMap, startWith, takeUntil } from 'rxjs/operators';
import { PrizeoutRecordsChangesTransfer } from '../Models/Inbound/PrizeoutRecordsChangesTransfer';
import { apiExportedFusionTdf } from '../Models/Outbound/Fusion/ApiExportedFusionTdf';
import { apiFusionTdfTransfer } from '../Models/Outbound/Fusion/ApiFusionTdfTransfer';
import { fusionDataChannel } from '../Models/Common/FusionDataChannel';
import { FusionTdfConfirmation } from '../Models/Outbound/Fusion/ApiFusionTdfConfirmation';
import { competitorDivision } from '../Models/Common/CompetitorDivision';

export class TournamentDataService {

    constructor(private acs: ApiConnectService) { }

    public GetAllEvents(): Observable<RequestResult<allEventsData>> {
        var apiAllEvents;
        console.log("Getting events list");
        apiAllEvents = this.acs.getAllEvents().pipe(map((response: AxiosResponse<apiResponse<allEventsData>>) => {
            return new RequestResult(response);
            }));
        return apiAllEvents;
    }

    public GetEventById(eventId: string): Observable<RequestResult<eventData>> {
        var apiEvent;
        console.log("Getting events with ID " + eventId);
        apiEvent = this.acs.getEventById(eventId).pipe(map((response: AxiosResponse<apiResponse<eventData>>) => {
            return new RequestResult(response);
            }));
        return apiEvent;
    }

    public CreateEvent(eventDetails: createEventTransfer): Observable<RequestResult<eventData>> {
        var apiEvent;
        var uploadPayload = JSON.stringify(eventDetails);
        console.log("Creating event with location " + eventDetails.eventDetails.location);
        apiEvent = this.acs.createEvent(uploadPayload).pipe(map((response: AxiosResponse<apiResponse<eventData>>) => {
            return new RequestResult(response);
            }));
        return apiEvent;
    }

    public EditEvent(eventId: string, eventDetails: createEventTransfer): Observable<RequestResult<eventData>> {
        var apiEvent;
        var uploadPayload = JSON.stringify(eventDetails);
        console.log("Editing event with ID " + eventId);
        apiEvent = this.acs.editEvent(uploadPayload, eventId).pipe(map((response: AxiosResponse<apiResponse<eventData>>) => {
            return new RequestResult(response);
            }));
        return apiEvent;
    }

    public PutEventState(eventId: string, eventState: eventState): Observable<RequestResult<eventData>> {
        var apiEvent;
        var eventStateTransfer: eventStateTransfer = { eventState: eventState };
        var uploadPayload = JSON.stringify(eventStateTransfer);
        console.log("Updating event state for event with ID " + eventId);
        apiEvent = this.acs.putEventState(uploadPayload, eventId).pipe(map((response: AxiosResponse<apiResponse<eventData>>) => {
            return new RequestResult(response);
            }));
        return apiEvent;
    }

    public GetPairingsData(eventId: string, division: competitorDivision, round: number | null): Observable<RequestResult<roundPairingWrapper>> {
        var requestResult;
        var convertedDivision = competitorDivision.apiComponent(division);
        if (round) {
            console.log("Getting pairings data for event " + eventId + ", round number " + round);
            requestResult = this.acs.getPairingsForRound(eventId, convertedDivision, round).pipe(map((response: AxiosResponse<apiResponse<roundPairingWrapper>>) => {
                console.log("Pairings loaded for event " + response?.data?.result?.eventId + ", round number " + response?.data?.result?.round);
                return new RequestResult(response);
            }));
        } else {
            console.log("Getting latest " + competitorDivision.name(division) + " pairings data for event " + eventId);
            requestResult = this.acs.getPairings(eventId, convertedDivision).pipe(map((response: AxiosResponse<apiResponse<roundPairingWrapper>>) => {
                console.log("Pairings loaded for event " + response?.data?.result?.eventId + ", round number " + response?.data?.result?.round);
                return new RequestResult(response);
            }));
        }
        return requestResult;
    }

    public GetPairingsTicker(eventId: string, division: competitorDivision): Observable<RequestResult<string>> {
        var convertedDivision = competitorDivision.apiComponent(division);
        var requestResult = this.acs.getPairings(eventId, convertedDivision).pipe(map((response: AxiosResponse<apiResponse<roundPairingWrapper>>) => {
            return new RequestResult(response).changeType<string>(response?.data?.result?.pairingsTicker ?? "No pairings data found.");
            }));
        return requestResult;
    }

    public GetLiveResultsTicker(eventId: string, division: competitorDivision): Observable<RequestResult<string>> {
        var convertedDivision = competitorDivision.apiComponent(division);
        var requestResult = this.acs.getPairings(eventId, convertedDivision).pipe(map((response: AxiosResponse<apiResponse<roundPairingWrapper>>) => {
                return new RequestResult(response).changeType<string>(response?.data?.result?.liveResultsTicker ?? "No pairings data found.");
            }));
        return requestResult;
    }

    public GetStandingsMessage(eventId: string, division: competitorDivision): Observable<RequestResult<string>> {
        var convertedDivision = competitorDivision.apiComponent(division);
        var requestResult = this.acs.getStandings(eventId, convertedDivision).pipe(map((response: AxiosResponse<apiResponse<apiStandings>>) => {
            const standingsList = response?.data?.result?.standings;
            if (!standingsList) {
                return new RequestResult(response).changeType<string>("No stream standings found.");
            }
            const standingsString = standingsList.map(standing => 
                `${standing.position} - ${standing.firstName} ${standing.lastName} (${standing.wins}/${standing.losses}/${standing.ties}) ${standing.dropRound ? '[DROPPED R' + standing.dropRound + "]" : ""}`
            ).join('\n');
            const standingsHeader = `Stream standings for ${competitorDivision.name(division)} division in R${response?.data?.result?.round} (${response?.data?.result?.isCalculated ? 'Calculated' : 'Not Calculated'}, ${response?.data?.result?.isFinal ? 'Final' : 'Not Final'}):\n\n`;
            const standingsMessage = standingsHeader + standingsString;
            return new RequestResult(response).changeType<string>(standingsMessage);
            }));
        return requestResult;
    }

    public GetPrizeoutMessage(eventId: string, division: competitorDivision): Observable<RequestResult<string>> {
        var requestResult = this.acs.getPrizeoutStandings(eventId).pipe(map((response: AxiosResponse<apiResponse<apiPrizeoutStandingsEvent | null>>) => {
            const selectedDivision = response?.data?.result?.divisions.find(d => d.division.valueOf() === division.valueOf());
            if (!selectedDivision || !selectedDivision.standings) {
                return new RequestResult(response).changeType<string>("No prize out standings found.");
            }
            const standingsString = selectedDivision.standings.map(standing => 
                `${standing.position} - ${standing.firstName} ${standing.lastName} (${standing.wins}/${standing.losses}/${standing.ties}) ${standing.dropRound ? '[DROPPED R' + standing.dropRound + "]" : ""}`
            ).join('\n');
            const standingsHeader = `Prize Out Standings for ${competitorDivision.name(division)} division in R${selectedDivision.round} (${selectedDivision.isCalculated ? 'Calculated' : 'Not Calculated'}, ${selectedDivision.isFinal ? 'Final' : 'Not Final'}):\n\n`;
            const standingsMessage = standingsHeader + standingsString;
            return new RequestResult(response).changeType<string>(standingsMessage);
            }));
        return requestResult;
    }

    public GetEndOfRoundMessage(eventId: string, division: competitorDivision): Observable<RequestResult<string>> {
        var convertedDivision = competitorDivision.apiComponent(division);
        var requestResult = this.acs.getEndOfRoundMessage(eventId, convertedDivision).pipe(map((response: AxiosResponse<apiResponse<toolkitResult>>) => {
            return new RequestResult(response).changeType<string>(response?.data?.result?.resultMessage ?? "No pairings data found.");
            }));
        return requestResult;
    }

    public UploadTdfFile(tdfTransfer: tdfTransfer, eventId: string): Observable<RequestResult<tdfUploadConfirmation>> {
        var uploadPayload = JSON.stringify(tdfTransfer);
        var requestResult = this.acs.postTdfFile(uploadPayload, eventId).pipe(map((response: AxiosResponse<apiResponse<tdfUploadConfirmation>>) => {
                return new RequestResult(response);
            }));
        return requestResult;
    };

    public UploadFinalTdfFile(tdfTransfer: tdfFinalTransfer, eventId: string): Observable<RequestResult<tdfUploadConfirmation>> {
        var uploadPayload = JSON.stringify(tdfTransfer);
        var requestResult = this.acs.postTdfFinalFile(uploadPayload, eventId).pipe(map((response: AxiosResponse<apiResponse<tdfUploadConfirmation>>) => {
                return new RequestResult(response);
            }));
        return requestResult;
    };

    public GetStreamData(eventId: string): Observable<RequestResult<streamDataResponse>> {
        console.log("Getting stream data for event " + eventId);
        var requestResult = this.acs.getStreamData(eventId).pipe(map((response: AxiosResponse<apiResponse<streamDataResponse>>) => {
                return new RequestResult(response);
            }));
        return requestResult;
    }

    public PostStreamData(streamData: streamDataTransfer, eventId: string): Observable<RequestResult<streamDataResponse>> {
        var uploadPayload = JSON.stringify(streamData);
        var requestResult = this.acs.postStreamData(uploadPayload, eventId).pipe(map((response: AxiosResponse<apiResponse<streamDataResponse>>) => {
                return new RequestResult(response);
            }));
        return requestResult;
    };

    public PostTournamentStandingsReport(standingsTransfer: standingsReportTransfer, eventId: string): Observable<RequestResult<apiStandings>> {
        var uploadPayload = JSON.stringify(standingsTransfer);
        var requestResult = this.acs.postStandings(uploadPayload, eventId).pipe(map((response: AxiosResponse<apiResponse<apiStandings>>) => {
                return new RequestResult(response);
            }));
        return requestResult;
    };

    public GetExportedTdf(eventId: string): Observable<RequestResult<exportedTdf>> {
        var requestResult = this.acs.getExportedTdf(eventId).pipe(map((response: AxiosResponse<apiResponse<exportedTdf>>) => {
                return new RequestResult(response);
            }));
        return requestResult;
    };

    public postImportVideoGameTeamsCsv(transfer: csvTransfer): Observable<RequestResult<LibraryEvent>> {
        var uploadPayload = JSON.stringify(transfer);
        var requestResult = this.acs.postImportVideoGameTeamsCsv(uploadPayload).pipe(map((response: AxiosResponse<apiResponse<LibraryEvent>>) => {
                return new RequestResult(response);
            }));
        return requestResult;
    };

    public GetStandings(eventId: string, division: competitorDivision): Observable<RequestResult<apiStandings>> {
        var convertedDivision = competitorDivision.apiComponent(division);
        var requestResult = this.acs.getStandings(eventId, convertedDivision).pipe(map((response: AxiosResponse<apiResponse<apiStandings>>) => {
            return new RequestResult(response);
            }));
        return requestResult;
    }

    public PostFusionImport(transfer: apiFusionTdfTransfer, eventId: string): Observable<RequestResult<FusionTdfConfirmation>> {
        var uploadPayload = JSON.stringify(transfer);
        var requestResult = this.acs.postFusionImport(uploadPayload, eventId).pipe(map((response: AxiosResponse<apiResponse<FusionTdfConfirmation>>) => {
            return new RequestResult(response);
            }));
        return requestResult;
    }

    public GetFusionExportCore(eventId: string): Observable<RequestResult<apiExportedFusionTdf>> {
        var requestResult = this.acs.getFusionExportCore(eventId).pipe(map((response: AxiosResponse<apiResponse<apiExportedFusionTdf>>) => {
            return new RequestResult(response);
            }));
        return requestResult;
    }

    public GetFusionExportSupport(eventId: string, channel: fusionDataChannel): Observable<RequestResult<apiExportedFusionTdf>> {
        var requestResult = this.acs.getFusionExportSupport(eventId, channel.valueOf()).pipe(map((response: AxiosResponse<apiResponse<apiExportedFusionTdf>>) => {
            return new RequestResult(response);
            }));
        return requestResult;
    }

    public GetPrizeoutStandingsRecords(eventId: string): Observable<RequestResult<apiPrizeoutStandingsEvent>> {
        var requestResult = this.acs.getPrizeoutStandingsRecords(eventId).pipe(map((response: AxiosResponse<apiResponse<apiPrizeoutStandingsEvent>>) => {
            return new RequestResult(response);
            }));
        return requestResult;
    }

    public PutPrizeoutRecordsChanges(transfer: PrizeoutRecordsChangesTransfer): Observable<RequestResult<null>> {
        var uploadPayload = JSON.stringify(transfer);
        var requestResult = this.acs.putPrizeoutRecordsChanges(uploadPayload).pipe(map((response: AxiosResponse<apiResponse<null>>) => {
            return new RequestResult(response);
            }));
        return requestResult;
    }

    public GetPairingsDataContinuous(
        eventId: string,
        division: competitorDivision,
        round: number | null,
        updateInterval: number,
        isContinuous: BehaviorSubject<boolean>,
        overrideTrigger: BehaviorSubject<void>): BehaviorSubject<RequestResult<roundPairingWrapper> | null> {
        const resultSubject = new BehaviorSubject<RequestResult<roundPairingWrapper> | null>(null);
        let subscription: Subscription | null = null;

        overrideTrigger.subscribe(() => {
            this.GetPairingsData(eventId, division, round).subscribe(result => {
                resultSubject.next(result);
            });
        });

        subscription = isContinuous.pipe(
            switchMap(active => {
                if (active) {
                    console.log("Received continuous pairings sync turned on");
                    return interval(updateInterval).pipe(
                        startWith(updateInterval),
                        switchMap(() => this.GetPairingsData(eventId, division, round)),
                        takeUntil(isContinuous.pipe(switchMap(val => val ? [] : [val])))
                    );
                } else {
                    console.log("Received continuous pairings sync turned off");
                    // if (subscription) {
                    //     subscription.unsubscribe();
                    // }
                    return [];
                }
            })
        ).subscribe(result => {
            resultSubject.next(result);
        });

        return resultSubject;
    }

    public GetPrizeoutStandingsRecordsContinuous(
        eventIds: string[],
        updateInterval: number,
        isContinuous: BehaviorSubject<boolean>,
        overrideTrigger: BehaviorSubject<void>): BehaviorSubject<RequestResult<apiPrizeoutStandingsEvent>[] | null> {
        const resultSubject = new BehaviorSubject<RequestResult<apiPrizeoutStandingsEvent>[] | null>(null);
        let subscription: Subscription;

        const fetchRecords = () => {
            const requests = eventIds.map(eventId => this.GetPrizeoutStandingsRecords(eventId));
            return requests.length ? requests : [new Observable<RequestResult<apiPrizeoutStandingsEvent>>((observer) => observer.complete())];
        };

        overrideTrigger.subscribe(() => {
            const results: RequestResult<apiPrizeoutStandingsEvent>[] = [];
            fetchRecords().forEach(request => {
                request.subscribe(result => {
                    results.push(result);
                    if (results.length === eventIds.length) {
                        resultSubject.next(results);
                    }
                });
            });
        });

        subscription = isContinuous.pipe(
            switchMap(active => {
                if (active) {
                    console.log("Received continuous prizeout standings sync turned on");
                    return interval(updateInterval).pipe(
                        startWith(updateInterval),
                        switchMap(() => {
                            const results: RequestResult<apiPrizeoutStandingsEvent>[] = [];
                            const requests = fetchRecords();
                            requests.forEach(request => {
                                request.subscribe(result => {
                                    results.push(result);
                                    if (results.length === eventIds.length) {
                                        resultSubject.next(results);
                                    }
                                });
                            });
                            return requests.length ? requests : [new Observable<RequestResult<apiPrizeoutStandingsEvent>>((observer) => observer.complete())];
                        }),
                        takeUntil(isContinuous.pipe(switchMap(val => val ? [] : [val])))
                    );
                } else {
                    console.log("Received continuous prizeout standings sync turned off");
                    // Not sure why I put this here but it breaks the continuous sync upon turning it off
                    // if (subscription) {
                    //     subscription.unsubscribe();
                    // }
                    return [];
                }
            })
        ).subscribe();

        return resultSubject;
    }
}