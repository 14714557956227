import { competitorDivision } from "../../Models/Common/CompetitorDivision";

export enum PrizeDefinitionConfiguratorDivision {
  Junior,
  Senior,
  Master,
  AllDivisions,
  Unknown
}

export namespace PrizeDefinitionConfiguratorDivision {
  export function name(state: PrizeDefinitionConfiguratorDivision): string {
    switch (state) {
      case PrizeDefinitionConfiguratorDivision.Junior:
        return "Junior Division";
      case PrizeDefinitionConfiguratorDivision.Senior:
        return "Senior Division";
      case PrizeDefinitionConfiguratorDivision.Master:
        return "Master Division";
      case PrizeDefinitionConfiguratorDivision.AllDivisions:
        return "All Division";
      case PrizeDefinitionConfiguratorDivision.Unknown:
        return "Unknown Division (error)";
    }
  }

  export function toCompetitorDivision(state: PrizeDefinitionConfiguratorDivision): competitorDivision {
    switch (state) {
      case PrizeDefinitionConfiguratorDivision.Junior:
        return competitorDivision.Junior;
      case PrizeDefinitionConfiguratorDivision.Senior:
        return competitorDivision.Senior;
      case PrizeDefinitionConfiguratorDivision.Master:
        return competitorDivision.Master;
      default:      
        throw new Error("Unable to convert PrizeDefinitionConfiguratorDivision to competitorDivision");
    }
  }

  export function fromCompetitorDivision(division: competitorDivision): PrizeDefinitionConfiguratorDivision {
    switch (division) {
      case competitorDivision.Junior:
        return PrizeDefinitionConfiguratorDivision.Junior;
      case competitorDivision.Senior:
        return PrizeDefinitionConfiguratorDivision.Senior;
      case competitorDivision.Master:
        return PrizeDefinitionConfiguratorDivision.Master;
      default:
        return PrizeDefinitionConfiguratorDivision.Unknown;
    }
  }
}