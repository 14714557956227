import { eventConfig } from "../../Models/Common/EventConfig";
import { eventDetails } from "../../Models/Common/EventDetails";
import { prizeoutPrizeType } from "../../Models/Common/PrizeoutPrizeType";
import { apiPrizeoutStanding, apiPrizeoutStandingsDivision, apiPrizeoutStandingsEvent, PrizeoutRecordEditAction } from "../../Models/Inbound/ApiPrizeoutStandingsEvent";
import { PrizeoutRecordsChange } from "../../Models/Inbound/PrizeoutRecordsChangesTransfer";
import { apiPrizeFulfilment } from "../../Models/Prizeout/ApiPrizeFulfilment";

export class PrizeoutStandingViewModel {
    // Data from parent event
    prizeoutUid: string;
    eventId: string;
    eventName: string;
    division: number;
    standingsLastUpdated: string;
    round: number;
    isFinal: boolean;

    // Data from server
    position: number | null;
    playerId: string | null;
    firstName: string;
    lastName: string;
    countryCode: string | null;
    wins: number;
    losses: number;
    ties: number;
    matchPoints: number | null;
    dropRound: number | null;
    editHistory: PrizeoutRecordEditAction[];

    localEditTime: string | null;
    prizingLastUpdated: string | null;
    localComment: string | null;
    remoteComment: string | null;
    prizefulfilments: PrizeoutFulfilmentViewModel[];

    constructor(apiStanding: apiPrizeoutStanding, event: apiPrizeoutStandingsEvent, division: apiPrizeoutStandingsDivision, eventDetails: eventDetails | null) {
        this.setServerData(apiStanding, event, division, eventDetails);
        this.localComment = null;
        this.localEditTime = null;
    }

    public isDropped(): boolean {
        return this.dropRound !== null;
    }

    public fullName(): string {
        return `${this.firstName} ${this.lastName}`;
    }

    public formattedPosition(): string {
        if (this.position === null) {
            return "N/A";
        }
        const suffix = ["th", "st", "nd", "rd"];
        const value = this.position % 100;
        return this.position + (suffix[(value - 20) % 10] || suffix[value] || suffix[0]);
    }

    public editPrizeFulfilment(prizeGuid: string, value: number | null) {
        const prize = this.prizefulfilments?.find(p => p.prizeGuid === prizeGuid);
        if (prize) {
            if (value === prize.localQuantityReceived) {
                prize.localQuantityReceived = null;
            } else {
                prize.localQuantityReceived = value;
            }
            this.localEditTime = new Date().toISOString();
        } else {
            console.log(`Prize with guid ${prizeGuid} not found`);
        }
    }

    public editComment(value: string | null) {
        // if (value === "" || value === " ") { value = null }
        if (value === this.remoteComment || (value === null && this.remoteComment === null)) {
            this.localComment = null;
            return;
        }
        this.localComment = value;
        this.localEditTime = new Date().toISOString();
    }

    public updateServerData(apiStanding: apiPrizeoutStanding, event: apiPrizeoutStandingsEvent, division: apiPrizeoutStandingsDivision, eventDetails: eventDetails | null) {
        // Reset local changes if they match the server data (prizing reset done during merge)
        if (this.localComment !== null && this.localComment === apiStanding.comment) {
            this.localComment = null;
        }
        if (this.localEditTime !== null && this.localEditTime === apiStanding.lastUpdated) {
            this.localEditTime = null;
        }
        this.setServerData(apiStanding, event, division, eventDetails);
    }

    public isEdited(): boolean {
        return this.localComment !== null || this.prizefulfilments?.some(p => p.IsEdited());
    }

    public prizesDue(): boolean {
        return this.prizefulfilments.length > 0;
    }

    public isFullyPrizedOut(): boolean {
        return this.prizefulfilments.every(p => p.IsPaidOut());
    }

    public prizesDueAndFullyPrizedOut(): boolean {
        return this.prizesDue() && this.isFullyPrizedOut();
    }

    public commentIsImportant(): boolean {
        return (this.localComment?.includes('!') ?? (this.remoteComment?.includes('!') ?? false));
    }

    public toPrizeoutRecordsChangeIfEdited(adminName: string): PrizeoutRecordsChange | null {
        if (this.isEdited() === false) {
            return null;
        }
        return {
            prizeoutUid: this.prizeoutUid,
            eventId: this.eventId,
            division: this.division,
            playerId: this.playerId ?? '',
            firstName: this.firstName,
            lastName: this.lastName,
            position: this.position,
            updatedPrizeFulfilments: this.prizefulfilments
            ?.filter(p => p.IsEdited())
            .map(p => p.ToApiPrizeFulfilment()) ?? [],
            comment: this.localComment,
            adminName: adminName,
            lastUpdated: this.localEditTime
        };
    }

    private setServerData(apiStanding: apiPrizeoutStanding, event: apiPrizeoutStandingsEvent, division: apiPrizeoutStandingsDivision, eventDetails: eventDetails | null) {
        this.position = apiStanding.position;
        this.playerId = apiStanding.playerId;
        this.firstName = apiStanding.firstName;
        this.lastName = apiStanding.lastName;
        this.countryCode = apiStanding.countryCode;
        this.wins = apiStanding.wins;
        this.losses = apiStanding.losses;
        this.ties = apiStanding.ties;
        this.matchPoints = apiStanding.matchPoints;
        this.dropRound = apiStanding.dropRound;

        // Merge prize fulfilments
        const newPrizefulfilments = apiStanding?.prizeFulfilments?.map(p => {
            if (!p.prizeGuid) {
            return null;
            }
            const existingPrize = this.prizefulfilments?.find(ep => ep.prizeGuid === p.prizeGuid);
            if (existingPrize) {
            existingPrize.MergeRemoteData(new PrizeoutFulfilmentViewModel(
                p.prizeGuid,
                p.quantityEarned,
                p.differentiator,
                p.type,
                null,
                p.quantityReceived
            ));
            return existingPrize;
            } else {
            return new PrizeoutFulfilmentViewModel(
                p.prizeGuid,
                p.quantityEarned,
                p.differentiator,
                p.type,
                null,
                p.quantityReceived
            );
            }
        }).filter(p => p !== null);

        this.prizefulfilments = newPrizefulfilments;
        this.prizingLastUpdated = apiStanding.lastUpdated;
        this.remoteComment = apiStanding.comment;
        this.editHistory = apiStanding.editHistory;

        this.prizingLastUpdated = apiStanding.lastUpdated;
        this.editHistory = apiStanding.editHistory;

        this.prizeoutUid = apiStanding.prizeoutUid;
        this.eventId = event.eventId;
        this.eventName = eventDetails?.location ?? 'unmatched';
        this.standingsLastUpdated = division.lastUpdated;
        this.division = division.division;
        this.round = division.round;
        this.isFinal = division.isFinal;
    }
}

export class PrizeoutFulfilmentViewModel {
    prizeGuid: string;
    quantityEarned: number | null;
    differentiator: string | null;
    type: prizeoutPrizeType;

    localQuantityReceived: number | null;
    remoteQuantityReceived: number | null;

    constructor(
        prizeGuid: string,
        quantityEarned: number | null,
        differentiator: string | null,
        type: prizeoutPrizeType,
        localQuantityReceived: number | null,
        remoteQuantityReceived: number | null
    ) {
        this.prizeGuid = prizeGuid;
        this.quantityEarned = quantityEarned;
        this.differentiator = differentiator;
        this.type = type;
        this.localQuantityReceived = localQuantityReceived;
        this.remoteQuantityReceived = remoteQuantityReceived;
    }

    get quantityReceivedReadable(): string {
        return (this.localQuantityReceived ?? this.remoteQuantityReceived ?? 0).toString();
    }

    get quantityEarnedReadable(): string {
        return (this.quantityEarned ?? 0).toString();
    }

    public MergeRemoteData(remote: PrizeoutFulfilmentViewModel) {
        this.quantityEarned = remote.quantityEarned;
        this.differentiator = remote.differentiator;
        this.type = remote.type;
        this.remoteQuantityReceived = remote.remoteQuantityReceived;
        if (this.localQuantityReceived !== null && this.localQuantityReceived === remote.remoteQuantityReceived) {
            console.log(`Local value ${this.localQuantityReceived} matches remote value ${remote.remoteQuantityReceived}; resetting local value`);
            this.localQuantityReceived = null
        }
    }

    public IsEdited(): boolean {
        return this.localQuantityReceived !== null;
    }

    public IsPaidOut(): boolean {
        const due = this.quantityEarned ?? 0;
        const awarded = this.remoteQuantityReceived ?? 0;
        const localAwarded = this.localQuantityReceived ?? awarded;
        return due > 0 && localAwarded >= due;
    }

    public ToApiPrizeFulfilment(): apiPrizeFulfilment {
        return {
            prizeGuid: this.prizeGuid,
            quantityEarned: this.quantityEarned ?? 0,
            quantityReceived: this.localQuantityReceived ?? this.remoteQuantityReceived ?? 0,
            differentiator: this.differentiator,
            type: this.type
        };
    }

    public ReadableType(): string {
        var isPlural = (this.quantityEarned ?? 0) > 1;
        return prizeoutPrizeType.toReadableString(this.type, isPlural)
    }

    public ReadableTypeWithDifferentiator(): string {
        if (this.type === prizeoutPrizeType.Other && this.differentiator) {
            return this.differentiator;
        }
        return (this.differentiator ? ` ${this.differentiator} ` : '') + this.ReadableType();
    }

    public PriorityOrder(): number {
        switch (this.type) {
            case prizeoutPrizeType.Trophy:
            return 0;
            case prizeoutPrizeType.Medal:
            return 1;
            case prizeoutPrizeType.Bag:
            return 2;
            case prizeoutPrizeType.Promo:
            return 3;
            case prizeoutPrizeType.Playmat:
            return 4;
            case prizeoutPrizeType.Other:
            return 5;
            case prizeoutPrizeType.Booster:
            return 6;
            case prizeoutPrizeType.PrizePoints:
            return 7;
            default:
            return 8;
        }
    }
}