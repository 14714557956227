import { useState, useMemo, useEffect, Fragment } from "react";
import '../../components/glassTheme/Common.scss';
import '../../components/glassTheme/Switch.scss';
import './EventsList.scss';
import React from 'react';
import { BehaviorSubject, Subscription } from "rxjs";
import SearchBar from "../../components/search-bar/SearchBar";
import Loader, { SpinnerType } from "../../components/Spinner/Loader";
import { GiKiwiBird, GiCancel } from "react-icons/gi";
import { FaFlagCheckered } from 'react-icons/fa';
import { FaRegTrashCan, FaRegCirclePlay } from "react-icons/fa6";
import { GrEmptyCircle } from "react-icons/gr";
import FilterButton, { FilterButtonType } from "../../components/filter-button/FilterButton";
import { eventViewModel } from "../EventsDashboard/EventsDashboard.ViewModel";
import { eventState } from "../../Models/Common/EventState";
import { RiExternalLinkFill } from "react-icons/ri";
import { loadingState } from "../../Models/Common/LoadingState";
import { gameType } from "../../Models/Common/GameType";
import { eventSeason } from "../../Models/Common/EventSeason";
import { eventType } from "../../Models/Common/EventType";
import { GiRollingDices, GiConsoleController, GiDuck } from "react-icons/gi";
import { MdPhoneIphone, MdDeleteForever } from "react-icons/md";
import { IoWarning } from "react-icons/io5";
import { FaChevronRight } from "react-icons/fa";
import ActionButton, { ActionButtonType } from "../../components/action-button/ActionButton";

interface EventsListProps {
    eventsSubject: BehaviorSubject<eventViewModel[]>;
    loadingStateSubject: BehaviorSubject<loadingState>;
    updateEventState: (eventId: string, newState: eventState) => void;
}

enum EventsFilterType {
    AllStates,
    Active,
    Finished,
    Alphabetical,
    LastUpdated,
    Search
}
const EventsList: React.FC<EventsListProps> = ({
    eventsSubject,
    loadingStateSubject,
    updateEventState,
}) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [events, setEvents] = useState<eventViewModel[]>([]);
    const [eventsFilter, setEventsFilter] = useState<EventsFilterType>(EventsFilterType.Active);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [selectedEvent, setSelectedEvent] = useState<eventViewModel | null>(null);

    useEffect(() => {
        const subs: Subscription[] = [];
        subs.push(
            eventsSubject.subscribe((events) => {
                setEvents(events ?? []);
            })
        );
        subs.push(
            loadingStateSubject.subscribe((state) =>
                setIsLoading(state !== loadingState.hidden)
            )
        );
        return () => subs.forEach((s) => s.unsubscribe());
    }, [eventsSubject, loadingStateSubject]);

    const filteredEvents = useMemo(() => {
        let filtered = events;
        switch (eventsFilter) {
            case EventsFilterType.Active:
                filtered = events.filter(
                    (event) =>
                        event.state === eventState.Active || event.state === eventState.New
                );
                break;
            case EventsFilterType.Finished:
                filtered = events.filter((event) => event.state === eventState.Finished);
                break;
            case EventsFilterType.Alphabetical:
                return [...events].sort((a, b) =>
                    a.eventId.localeCompare(b.eventId)
                );
            case EventsFilterType.LastUpdated:
                return [...events].sort((a, b) =>
                    b.pairingsLastUpdated.localeCompare(a.pairingsLastUpdated)
                );
            case EventsFilterType.Search:
                if (searchTerm === "") return events;
                filtered = events.filter((event) =>
                    event.eventId.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    event.details?.location?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    event.eventGroupId?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    eventSeason.name(event.details?.season).toLowerCase().includes(searchTerm.toLowerCase()) ||
                    eventType.name(event.details?.type).toLowerCase().includes(searchTerm.toLowerCase())
                );
                break;

            default:
                return events;
        }

        if (
            eventsFilter === EventsFilterType.Active ||
            eventsFilter === EventsFilterType.Finished ||
            eventsFilter === EventsFilterType.Search
        ) {
            return [...filtered].sort((a, b) =>
                b.pairingsLastUpdated.localeCompare(a.pairingsLastUpdated)
            );
        }
        return filtered;
    }, [events, eventsFilter, searchTerm]);

    const EventsFilterValueChange = (value: EventsFilterType) => {
        if (eventsFilter === value) {
            return;
        }
        setEventsFilter(value);
        setSearchTerm(""); // Reset search term when filter changes
    };

    const searchTermValueChange = (value: string) => {
        setEventsFilter(EventsFilterType.Search);
        setSearchTerm(value);
    };

    if (isLoading) {
        return (
            <Loader
                showMessage={true}
                spinnerType={SpinnerType.FullPage}
                loadingStateSubject={loadingStateSubject}
            />
        );
    }

    return (
        <div className="eventsControls">
            <Fragment key={filteredEvents.length}>
                <ul className="events">
                    {filteredEvents.map((event) => (
                        <Fragment key={event.eventId}>
                            <li className="event" key={event.eventId}>
                                <div className={`event-card${selectedEvent?.eventId === event.eventId ? '-selected' : ''}`} onClick={() => setSelectedEvent(event)}>
                                    <div className="event-data-section-start">
                                        <div className="event-data-column">
                                            { event.details?.gameType === gameType.TradingCardGame &&<GiRollingDices className="state-icon"/>}
                                            { event.details?.gameType === gameType.VideoGame && <GiConsoleController className="state-icon"/>}
                                            { event.details?.gameType === gameType.GO && <MdPhoneIphone className="state-icon"/>}
                                            { event.details?.gameType === gameType.Other && <GiDuck className="state-icon"/>}
                                            <label className="state-label">{gameType.shortName(event.details?.gameType)}</label>
                                        </div>
                                        <div className="event-details-column">
                                            {event.eventGroupId && <label className="eventGroupBadge">{event.eventGroupId}</label>}
                                            <label className="event-title">{event.displayName()}</label>
                                            <label className="eventId-label">{event.eventId}</label>
                                        </div>
                                    </div>
                                    <div className="event-data-section-middle">
                                        <div className="event-data-column">
                                            <div className="data-pair">
                                                { event.state === eventState.New &&<GrEmptyCircle className="state-icon"/>}
                                                { event.state === eventState.Active && <FaRegCirclePlay className="state-icon"/>}
                                                { event.state === eventState.Finished && <FaFlagCheckered className="state-icon"/>}
                                                { event.state === eventState.Cancelled && <GiCancel className="state-icon"/>}
                                                { event.state === eventState.Deleted && <FaRegTrashCan className="state-icon"/>}
                                                <label className="state-label">{eventState.name(event.state)}</label>
                                            </div>
                                        </div>
                                        <div className="event-data-column-left">
                                            <div className="data-pair">
                                                <label className="event-detail-header"><i>Pairings Updated</i></label>
                                                <label className="event-detail">{event.pairingsReadableLastUpdated != "" ? event.pairingsReadableLastUpdated : "Never"}</label>
                                            </div>
                                            <div className="data-pair">
                                                <label className="event-detail-header"><i>Standings Updated</i></label>
                                                <label className="event-detail">{event.standingsReadableLastUpdated != "" ? event.standingsReadableLastUpdated: "Never"}</label>
                                            </div>
                                            <div className="data-pair">
                                                <label className="event-detail-header"><i>Update Count:</i></label>
                                                <label className="event-detail">{event.pairingsUpdateCount} Pairings, {event.standingsUpdateCount} Standings</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="event-data-section-end">
                                        <div className="data-pair">
                                        <label className="glass-caption bold">Config</label>
                                            <label className="glass-caption italic">{event.config.isPrizeoutEnabled ? 'Prize out enabled' : 'Prize out disabled'}</label>
                                            <label className="glass-caption italic">{event.config.calculatePrizeoutStandingsOnUpload ? 'Auto prizeout standings' : ''}</label>
                                            <label className="glass-caption italic">{event.config.calculateStreamStandingsOnUpload ? 'Auto stream standings' : ''}</label>
                                        </div>
                                    </div>
                                    <div className="event-data-section-action" style={{ visibility: selectedEvent?.eventId === event.eventId ? 'hidden' : 'visible' }}>
                                        <FaChevronRight className="event-data-action-icon"/>
                                    </div>
                                </div>
                            </li>
                            {selectedEvent?.eventId === event.eventId && (
                                <div className="nav-button-container">
                                    { event.state === eventState.New || event.state === eventState.Active || event.state === eventState.Finished ?
                                        <button className="nav-button" onClick={() => updateEventState(event.eventId, eventState.Cancelled)}>Cancel Event <IoWarning className="glass-button-icon-right" /></button> : null }
                                    { event.state === eventState.Cancelled ?
                                        <button className="nav-button" onClick={() => updateEventState(event.eventId, eventState.Deleted)}>Delete Event <MdDeleteForever className="glass-button-icon-right" /></button> : null }
                                    <a className="nav-button" href={"/web/events/" + event.eventId + "/edit"} target="">Edit <RiExternalLinkFill className="glass-button-icon-right" /></a>
                                    <a className="nav-button" href={"/web/events/" + event.eventId + "/pairings"} target="">Pairings <RiExternalLinkFill className="glass-button-icon-right" /></a>
                                    <a className="nav-button" href={"/web/events/" + event.eventId} target="">Scorekeeper <RiExternalLinkFill className="glass-button-icon-right" /></a>
                                    <a className="nav-button" href={"/web/events/" + event.eventId + "/stream"} target="">Stream <RiExternalLinkFill className="glass-button-icon-right" /></a>
                                    <a className="nav-button" href={"/web/events/" + event.eventId + "/standings"} target="">Standings <RiExternalLinkFill className="glass-button-icon-right" /></a>
                                </div>
                            )}
                        </Fragment>
                    ))}
                    {filteredEvents.length === 0 && (
                        <div className="searchError">
                            <label className="glass-h3 italic">
                                No events found for current filters.
                            </label>
                            <GiKiwiBird className="searchErrorIcon" />
                        </div>
                    )}
                </ul>
            </Fragment>
            <div className="adminControls">
                <ActionButton
                    text="Create Event"
                    onPress={() => window.location.href = "/web/create-event"}
                    iconType={ActionButtonType.Create}/>
            </div>
            <div className="filterControls">
                <FilterButton
                    isToggled={eventsFilter === EventsFilterType.AllStates}
                    onToggle={() =>
                        EventsFilterValueChange(EventsFilterType.AllStates)
                    }
                    iconType={FilterButtonType.AllStates}
                />
                <FilterButton
                    isToggled={eventsFilter === EventsFilterType.Active}
                    onToggle={() => EventsFilterValueChange(EventsFilterType.Active)}
                    iconType={FilterButtonType.Active}
                />
                <FilterButton
                    isToggled={eventsFilter === EventsFilterType.Finished}
                    onToggle={() =>
                        EventsFilterValueChange(EventsFilterType.Finished)
                    }
                    iconType={FilterButtonType.Finished}
                />
                <FilterButton
                    isToggled={eventsFilter === EventsFilterType.Alphabetical}
                    onToggle={() =>
                        EventsFilterValueChange(EventsFilterType.Alphabetical)
                    }
                    iconType={FilterButtonType.Alphabetical}
                />
                <FilterButton
                    isToggled={eventsFilter === EventsFilterType.LastUpdated}
                    onToggle={() =>
                        EventsFilterValueChange(EventsFilterType.LastUpdated)
                    }
                    iconType={FilterButtonType.LastUpdated}
                />
                <SearchBar
                    searchTerm={searchTerm}
                    onSearch={searchTermValueChange}
                />
            </div>
        </div>
    );
};

export default EventsList;