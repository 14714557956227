import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.scss';
import Home from '../Home/Home';
import EventsDashboard from '../EventsDashboard/EventsDashboard/EventsDashboard';
import CreateEvent from '../EventsDashboard/CreateEvent/CreateEvent';
import PairingsDashboard from '../PairingsDashboard/PairingsDashboard/PairingsDashboard';
import Navbar from '../components/navbar/Navbar';
import Background from '../components/background/Background';
import { NavbarProperties } from '../components/navbar/Navbar.ViewModel';
import { useEffect } from 'react';
import ScorekeeperDashboard from '../ScorekeeperDashboard/ScorekeeperDashboard/ScorekeeperDashboard';
import DataImport from '../DataImport/DataImport';
import StandingsDashboard from '../StandingsDashboard/StandingsDashboard/StandingsDashboard';
import PrizeoutDashboard from '../PrizeoutDashboard/PrizeoutDashboard/PrizeoutDashboard';
import ErrorPage, { ErrorType } from './Common/ErrorPage/ErrorPage';

function App() {
  const navbarProperties = new NavbarProperties();

  useEffect(() => {
    const metaThemeColor = document.querySelector('meta[name="theme-color"]');
    if (metaThemeColor) {
      metaThemeColor.setAttribute('content', '#5233bd');
    }
  }, []);
  
  
  return (
    <div className="app">
      <div className="app-bgroot"><Background/></div>
        <div className="app-header">
          <Navbar properties={navbarProperties}></Navbar>
        </div>
        <div className="app-content">     
          <Router>
            <Routes>
              <Route path="/" element={<Home/>}/>
              <Route path="/web/data-import" element={<DataImport navbarDataSubject={navbarProperties.data}/>}/>
              <Route path="/web/events" element={<EventsDashboard navbarDataSubject={navbarProperties.data}/>}/>
              <Route path="/web/create-event" element={<CreateEvent navbarDataSubject={navbarProperties.data} isEditMode={false} />}/>
              <Route path="/web/events/:eventId/edit" element={<CreateEvent navbarDataSubject={navbarProperties.data} isEditMode={true}/>}/>
              <Route path="/web/events/:eventId" element={<ScorekeeperDashboard navbarDataSubject={navbarProperties.data} />}/>
              <Route path="/web/events/:eventId/pairings" element={<PairingsDashboard navbarProperties={navbarProperties} isReadOnly={true}/>}/>
              <Route path="/web/events/:eventId/stream" element={<PairingsDashboard navbarProperties={navbarProperties} isReadOnly={false}/>}/>
              <Route path="/web/events/:eventId/stream/:roundNumber" element={<PairingsDashboard navbarProperties={navbarProperties} isReadOnly={false}/>}/>
              <Route path="/web/events/:eventId/standings" element={<StandingsDashboard navbarDataSubject={navbarProperties.data}/>}/>
              <Route path="/web/prizeout" element={<PrizeoutDashboard navbarProperties={navbarProperties} isReadOnly={false}/>}/>
              <Route path="/web/scorekeeper" element={<ScorekeeperDashboard navbarDataSubject={navbarProperties.data}/>}/>
              <Route path="/unauthorised" element={<ErrorPage errorType={ErrorType.Unauthorized} />} />
              <Route path="/forbidden" element={<ErrorPage errorType={ErrorType.Forbidden} />} />
              <Route path="/not-found" element={<ErrorPage errorType={ErrorType.NotFound} />} />
              <Route path="*" element={<ErrorPage errorType={ErrorType.NotFound} />} />
            </Routes>
          </Router>
        </div>
    </div>
  );
}

export default App;