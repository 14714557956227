import React, { ChangeEvent } from 'react';
import { prizeoutPrizeType } from '../../Models/Common/PrizeoutPrizeType';

interface PrizeTypeSelectorProps {
  value: prizeoutPrizeType;
  isDark?: boolean;
  onChange: (prizeType: prizeoutPrizeType) => void;
}

const PrizeTypeSelector: React.FC<PrizeTypeSelectorProps> = ({ value, isDark = false, onChange }) => {
  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedPrizeType = Number(event.target.value) as prizeoutPrizeType;
    onChange(selectedPrizeType);
  };

  return (
    <select
      id="prize-type-select"
      value={value}
      onChange={handleChange}
      className={isDark ? "dark-glass-select" : "glass-select"}
    >
      {Object.values(prizeoutPrizeType)
        .filter((value) => typeof value === 'number')
        .map((prizeType) => (
          <option key={prizeType} value={prizeType}>
            {prizeoutPrizeType.name(prizeType)}
          </option>
        ))}
    </select>
  );
};

export default PrizeTypeSelector;