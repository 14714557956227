import React, { useEffect } from 'react';
import './PrizeDefinitionConfigurator.scss';
import { PrizeDefinitionConfiguratorViewModel } from './PrizeDefinitionConfigurator.ViewModel';
import PrizeDefinitionConfiguratorRow from './PrizeDefinitionConfiguratorRow/PrizeDefinitionConfiguratorRow';
import IconButton, { IconButtonType } from '../../components/IconButton/IconButton';
import { PrizeDefinitionConfiguratorDivision } from './PrizeDefinitionConfiguratorDivision';

interface PrizeDefinitionConfiguratorProps {
  viewModel: PrizeDefinitionConfiguratorViewModel;
  updateViewModel: (viewModel: PrizeDefinitionConfiguratorViewModel) => void;
}

const PrizeDefinitionConfigurator: React.FC<PrizeDefinitionConfiguratorProps> = ({ viewModel, updateViewModel })  => {
  const [division, setDivision] = React.useState(viewModel.division);
  const [definitions, setDefinitions] = React.useState(viewModel.definitions);

  useEffect(() => {
    setDefinitions(viewModel.definitions);
  }, [viewModel]);

  return (
    <div className="prizeoutDefinition-container">
      <label className="glass-h3 italic">{PrizeDefinitionConfiguratorDivision.name(division)} Prizes</label>
        {definitions.map((definition, index) => (
        <React.Fragment key={index}>
          <PrizeDefinitionConfiguratorRow
          viewModel={definition}
          updatePrizeDefinition={(updatedDefinition) => {
            viewModel.UpdateDefinition(updatedDefinition);
            setDefinitions(viewModel.definitions);
            updateViewModel(viewModel);
          }}/>
          <div className="delete-divider">
            <div className="glass-divider"></div>
            <IconButton isEnabled={true} hasShadow={false} iconType={IconButtonType.Trash} label="Delete" hasBackground={false} onPress={() => {
              viewModel.DeleteDefinition(definition)
              setDefinitions(viewModel.definitions);
              updateViewModel(viewModel)
            }}/>
          </div>
        </React.Fragment>
        ))}
        <div className="prizeoutDefinition-footer">
        <button className="glass-button" onClick={() => {
          viewModel.AddNewDefinition();
          setDefinitions(viewModel.definitions);
          updateViewModel(viewModel);
        }}>Add Prize</button>
      </div>
    </div>
  );
}

export default PrizeDefinitionConfigurator;