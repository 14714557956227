import React, { useEffect, useState } from 'react';
import "./NavbarSyncTool.scss"
import { RequestResultType } from '../../../Models/Common/RequestResult';
import { motion } from 'framer-motion';
import { FaXmark, FaCheck, FaExclamation, FaArrowUp, FaPlay} from "react-icons/fa6";
import { FaRegListAlt } from "react-icons/fa";
import { GrSync } from "react-icons/gr";
import { Subscription } from 'rxjs';
import { SyncToolConfig } from '../Navbar.ViewModel';
import { FaSyncAlt } from "react-icons/fa";
import { FaPause } from "react-icons/fa6";
import { AiOutlineDisconnect } from "react-icons/ai";

interface NavbarSyncToolProps {
    config: SyncToolConfig;
}

export enum NavbarSyncToolIcon {
    Sync,
    Report,
    StreamData,
    Disabled
}


const NavbarSyncTool: React.FC<NavbarSyncToolProps> = ({config}) => {
    const [isSyncFeatureEnabled, setIsSyncFeatureEnabled] = useState(false);
    const [isSyncActive, setIsSyncActive] = useState(false);
    const [configData, setConfigData] = useState(config.data.value);
    const [isHoveringActionButton, setIsHoveringActionButton] = useState(false);

    useEffect(() => {
        const subs: Subscription[] = [];
        subs.push(
            config.isSyncFeatureEnabled.subscribe((value) => {
                console.log("Sync enabled: " + value);
                setIsSyncFeatureEnabled(value);
            })
        );subs.push(
            config.isSyncActive.subscribe((value) => {
                console.log("Sync active: " + value);
                setIsSyncActive(value);
            })
        );subs.push(
            config.data.subscribe((value) => {
                setConfigData(value);
            })
        );
        return () => subs.forEach((s) => s.unsubscribe());
    }, [config]);

    const toggleSync = () => {
        if (isSyncFeatureEnabled) {
            config.isSyncActive.next(!isSyncActive);
            console.log("Sync is now " + !isSyncActive);
        }
    }

    const getDefaultIcon = () => {
        switch (configData.defaultIcon) {
            case NavbarSyncToolIcon.Sync:
                return <FaSyncAlt className='resultSyncIconActive'/>;
            case NavbarSyncToolIcon.Report:
                return <FaRegListAlt className='resultSyncIcon'/>;
            case NavbarSyncToolIcon.StreamData:
                return <GrSync className='resultSyncIcon'/>;
            case NavbarSyncToolIcon.Disabled:
                return <AiOutlineDisconnect className='resultSyncIcon'/>;
        }
    }

    const renderView = () => {
            if (configData.isFetching) {
                return (
                    <div className="navbarSyncToolUploading">
                        <div className="navbarSyncToolData">
                        <label className="resultSyncSubtitle">{configData.caption}</label>
                            <label className="resultSyncTitle">{configData.title}</label>
                            <label className="resultSyncSubtitle">{configData.message}</label>
                        </div>
                        <div className="resultSyncIndicator">
                            <div className="resultSyncIndicatorDefault">{getDefaultIcon()}</div>
                            <div className="resultSyncIndicatorStatusDefault"><FaArrowUp className="statusIcon"/></div>
                        </div>
                    </div>
                );
            } else if (configData.result === null || configData.result === undefined) {
                return (
                    <div className="navbarSyncToolDefault">
                        <div className="navbarSyncToolData">
                            <label className="resultSyncSubtitle">{configData.caption}</label>
                            <label className="resultSyncTitle">{configData.title}</label>
                            <label className="resultSyncSubtitle">{configData.message}</label>
                        </div>
                        <div className="resultSyncIndicator">
                            <div className="resultSyncIndicatorDefault">{getDefaultIcon()}</div>
                            {/* <div className="resultSyncIndicatorStatusFetching"><FaRegFileAlt className="statusIcon"/></div> */}
                        </div>
                    </div>
                );
            } else if (configData.result.isAnySuccess()) {
                return (
                    <div className="navbarSyncToolSuccess">
                        <div className="navbarSyncToolData">
                        <label className="resultSyncSubtitle">{configData.caption}</label>
                            <label className="resultSyncTitle">{isSyncActive ? "Sync active" : "Sync paused"}</label>
                            <label className="resultSyncSubtitle">{configData.message}</label>
                        </div>
                        <div className="resultSyncIndicator" onClick={() => toggleSync()}  onMouseEnter={() => setIsHoveringActionButton(true)} onMouseLeave={() => setIsHoveringActionButton(false)}>
                            <div className="resultSyncIndicatorDefault">
                                {isSyncActive ? (
                                    isHoveringActionButton ? (
                                        <FaPause className='resultSyncIcon'/>
                                    ) : (
                                        getDefaultIcon()
                                    )
                                ) : (
                                    <FaPlay className='resultSyncIcon'/>
                                )}
                            </div>
                            <div className="resultSyncIndicatorStatusSuccess"><FaCheck className="statusIcon"/></div>
                        </div>
                    </div>
                );
            } else if (configData.result.resultType === RequestResultType.ConnectionError || configData.result.resultType === RequestResultType.ClientError) {
                return (
                    <div className="navbarSyncToolWarning">
                        <div className="navbarSyncToolData">
                            <label className="resultSyncSubtitle">{configData.caption}</label>
                            <label className="resultSyncTitle">{configData.result.summaryTitle}</label>
                            <label className="resultSyncSubtitle">{configData.message}</label>
                        </div>
                        <div className="resultSyncIndicator" onClick={() => toggleSync()}  onMouseEnter={() => setIsHoveringActionButton(true)} onMouseLeave={() => setIsHoveringActionButton(false)}>
                            <div className="resultSyncIndicatorDefault">
                                {isSyncActive ? (
                                    isHoveringActionButton ? (
                                        <FaPause className='resultSyncIcon'/>
                                    ) : (
                                        getDefaultIcon()
                                    )
                                ) : (
                                    <FaPlay className='resultSyncIcon'/>
                                )}
                            </div>
                            <div className="resultSyncIndicatorStatusWarning"><FaExclamation className="statusIcon"/></div>
                        </div>
                    </div>
                );
            } else {
                return (
                    <div className="navbarSyncToolError">
                        <div className="navbarSyncToolData">
                            <label className="resultSyncSubtitle">{configData.caption}</label>
                            <label className="resultSyncTitle">{configData.result.summaryTitle}</label>
                            <label className="resultSyncSubtitle">{configData.message}</label>
                        </div>
                        <div className="resultSyncIndicatorError"><FaXmark className="resultSyncIcon"/></div>
                    </div>
                );
            }
        }

    return (
        <motion.div
            className="navbarSyncToolContainer">
            {renderView()}
        </motion.div>
    )
};

export default NavbarSyncTool;