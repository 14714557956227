import React, { ChangeEvent } from 'react';
import { competitorDivision } from '../../Models/Common/CompetitorDivision';
interface DivisionSelectorProps {
  value: competitorDivision;
  isDark?: boolean;
  onChange: (division: competitorDivision) => void;
}

const DivisionSelector: React.FC<DivisionSelectorProps> = ({ value, isDark = false, onChange }) => {
  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedDivision = Number(event.target.value) as competitorDivision;
    onChange(selectedDivision);
  };

  return (
      <select
        id="division-select"
        value={value}
        onChange={handleChange}
        className={isDark ? "dark-glass-select" : "glass-select"}
      >
        {Object.values(competitorDivision)
          .filter((value) => typeof value === 'number')
          .map((division) => (
        <option key={division} value={division}>
          {competitorDivision.name(division as competitorDivision)}
        </option>
          ))}
      </select>
  );
};
export default DivisionSelector;