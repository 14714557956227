import { useEffect } from "react";
import { competitorDivision, competitorDivisionfromString } from "../Models/Common/CompetitorDivision";
import { fusionDataChannel } from "../Models/Common/FusionDataChannel";

export class QueryParamService {

    static alertOnRefresh = () => {
        useEffect(() => {
            const handleBeforeUnload = (event: BeforeUnloadEvent) => {
                event.preventDefault();
            };
            window.addEventListener('beforeunload', handleBeforeUnload);
            return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
            };
        }, []);
    }

    static getDivision = (queryParams: URLSearchParams): competitorDivision => {
        let divisionParam = queryParams.get('division');
        let division = competitorDivisionfromString(divisionParam ?? "");
        if (divisionParam === null || division === null) {
            queryParams.set('division', 'master');
            division = competitorDivision.Master;
            window.history.replaceState({}, '', `${window.location.pathname}?${queryParams.toString()}`);
        }
        return division;
    };

    static getFusionDataChannel = (queryParams: URLSearchParams): fusionDataChannel => {
        let channelParam = queryParams.get('channel');
        let channel = fusionDataChannel.fromParamString(channelParam ?? "");
        if (channelParam === null || channel === null) {
            queryParams.set('channel', 'none');
            channel = fusionDataChannel.none;
            window.history.replaceState({}, '', `${window.location.pathname}?${queryParams.toString()}`);
        }
        return channel;
    };

    static setFusionDataChannel = (queryParams: URLSearchParams, channel: fusionDataChannel) => {
        queryParams.set('channel', fusionDataChannel.toParamString(channel));
        window.history.replaceState({}, '', `${window.location.pathname}?${queryParams.toString()}`);
    }

    static getRound = (queryParams: URLSearchParams): (number | null) => {
        let roundParam = queryParams.get('round');
        let round: number | null = null;
        if (roundParam) {
            if (roundParam === 'latest') {
                round = null;
            } else {
                const parsedRound = Number(roundParam);
                if (!isNaN(parsedRound)) {
                    round = parsedRound;
                } else {
                    queryParams.set('round', 'latest');
                    window.history.replaceState({}, '', `${window.location.pathname}?${queryParams.toString()}`);
                }
            }
        } else {
            queryParams.set('round', 'latest');
            window.history.replaceState({}, '', `${window.location.pathname}?${queryParams.toString()}`);
        }
        return round;
    }
}