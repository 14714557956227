import React, { ChangeEvent } from 'react';
import { eventSeason } from '../../Models/Common/EventSeason';

interface EventSeasonSelectorProps {
  value: eventSeason;
  isDark?: boolean;
  onChange: (eventSeason: eventSeason) => void;
}

const EventSeasonSelector: React.FC<EventSeasonSelectorProps> = ({ value, isDark = false, onChange }) => {
  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedEventSeason = Number(event.target.value) as eventSeason;
    onChange(selectedEventSeason);
  };

  return (
    <select
      id="event-season-select"
      value={value}
      onChange={handleChange}
      className={isDark ? "dark-glass-select" : "glass-select"}
    >
      {Object.values(eventSeason)
        .filter((value) => typeof value === 'number')
        .map((season) => (
          <option key={season} value={season}>
            {eventSeason.name(season)}
          </option>
        ))}
    </select>
  );
};

export default EventSeasonSelector;