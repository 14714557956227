
import { apiResponse } from '../Models/Common/ApiResponse';
import axios, { AxiosError, AxiosResponse, Method } from 'axios';
import { Observable, from } from 'rxjs';
import { toolkitResult } from '../Models/Outbound/ToolkitResult';
import { apiStandings } from '../Models/Inbound/ApiStandings';
import { roundPairingWrapper } from '../Models/Outbound/RoundPairingWrapper';
import { streamDataResponse } from '../Models/Outbound/StreamDataResponse';
import { allEventsData } from '../Models/Outbound/AllEventsData';
import { eventData } from '../Models/Outbound/EventData';
import { tdfUploadConfirmation } from '../Models/Outbound/TdfUploadConfirmation';
import { exportedTdf } from '../Models/Outbound/ExportedTdf';
import { LibraryEvent } from '../Models/Library/LibraryEvent';
import { apiPrizeoutStandingsEvent } from '../Models/Inbound/ApiPrizeoutStandingsEvent';
import { apiExportedFusionTdf } from '../Models/Outbound/Fusion/ApiExportedFusionTdf';
import { FusionTdfConfirmation } from '../Models/Outbound/Fusion/ApiFusionTdfConfirmation';

export class ApiConnectService {
  apiRootUrl: string;
  apiEventUrl: string;
  apiPrizeoutUrl: string;
  apiRootUrlPrefix: string = process.env.REACT_APP_API_ROOT_URL_PREFIX ?? "https://";
  apiDomain: string = "/api/";
  eventDomain: string = "events/";
  createEventDomain: string = "create-event";
  prizeoutDomain: string = "prizeout/";
  allEventsExtension: string = "events";
  editEventExtension: string = "/edit";
  putEventStateExtension: string = "/update-state";
  uploadExtension: string = "/tdf/upload";
  uploadFinalExtension: string = "/tdf/upload-final";
  uploadStandingsExtension: string = "/standings/upload/";
  getStandingsExtension: string = "/standings/";
  uploadStreamDataExtension: string = "/stream/upload/";
  getStreamDataExtension: string = "/stream/";
  getEndOfRoundExtension: string = "/pairings/endOfRound/";
  getPairingsExtension: string = "/pairings/";
  getRoundExtension: string = "/round/";
  getExportedTdfExtension: string = "/tdf/export";
  postFusionImportExtension: string = "/import";
  getFusionExportCoreExtension: string = "/export/core";
  getFusionExportSupportExtension: string = "/export/support/";
  getPrizeoutStandingsExtension: string = "/prizeout/records";
  putPrizeoutChangesExtension: string = "records/upload-changes";

  apiAnalyticsUrl: string;
  analyticsDomain: string = "analytics/";
  importExtension: string = "import/";
  fusionExtension: string = "/tdf-fusion";
  vgTeamsCsvExtension: string = "vg-teams-csv/";

  usingLocalAPI = true;
  usingAzureDevAPI = true;

  constructor() {
    this.apiRootUrl = this.apiRootUrlPrefix + (process.env.REACT_APP_API_ROOT_URL ?? "_ENVIROMENT_VARIABLE_NOT_SET_") + this.apiDomain;
    this.apiEventUrl = this.apiRootUrl + this.eventDomain;
    this.apiPrizeoutUrl = this.apiRootUrl + this.prizeoutDomain;
    this.apiAnalyticsUrl = this.apiRootUrl + this.analyticsDomain;
  }

  axiosConfig = {
    headers: {
      'AuthorizationToken': process.env.REACT_APP_API_FRONTEND_HEADER_AUTH_TOKEN ?? '_ENVIROMENT_VARIABLE_NOT_SET_',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Credentials':true,
    },
    params: {
    }
  };

  getAllEvents(): Observable<AxiosResponse<apiResponse<allEventsData>>> {
    console.log("api call - getAllEvents")
    return this.executeApiRequest(this.apiRootUrl + this.allEventsExtension, 'GET', null);
  }

  getEventById(eventId: string): Observable<AxiosResponse<apiResponse<eventData>>> {
    console.log("api call - getEventById")
    return this.executeApiRequest(this.apiEventUrl + eventId, 'GET', null);
  }

  createEvent(postBody: string): Observable<AxiosResponse<apiResponse<eventData>>> {
    console.log("api call - createEvent")
    return this.executeApiRequest(this.apiRootUrl + this.createEventDomain, 'POST', postBody);
  }

  editEvent(postBody: string, eventId: string): Observable<AxiosResponse<apiResponse<eventData>>> {
    console.log("api call - editEvent")
    return this.executeApiRequest(this.apiEventUrl + eventId + this.editEventExtension, 'POST', postBody);
  }

  putEventState(putBody: string, eventId: string): Observable<AxiosResponse<apiResponse<eventData>>> {
    console.log("api call - putEventState")
    return this.executeApiRequest(this.apiEventUrl + eventId + this.putEventStateExtension, 'PUT', putBody);
  }

  postTdfFile(postBody: string, eventId: string): Observable<AxiosResponse<apiResponse<tdfUploadConfirmation>>> {
    console.log("api call - postTdfFile")
    return this.executeApiRequest(this.apiEventUrl + eventId + this.uploadExtension, 'POST', postBody);
  }

  postTdfFinalFile(postBody: string, eventId: string): Observable<AxiosResponse<apiResponse<tdfUploadConfirmation>>> {
    console.log("api call - postTdfFile")
    return this.executeApiRequest(this.apiEventUrl + eventId + this.uploadFinalExtension, 'POST', postBody);
  }

  postStandings(postBody: string, eventId: string): Observable<AxiosResponse<apiResponse<apiStandings>>> {
    console.log("api call - postStandings")
    return this.executeApiRequest(this.apiEventUrl + eventId + this.uploadStandingsExtension, 'POST', postBody);
  }

  postStreamData(postBody: string, eventId: string): Observable<AxiosResponse<apiResponse<streamDataResponse>>> {
    console.log("api call - postStreamData")
    return this.executeApiRequest(this.apiEventUrl + eventId + this.uploadStreamDataExtension, 'POST', postBody);
  }

  getPairings(eventId: string, division: string): Observable<AxiosResponse<apiResponse<roundPairingWrapper>>> {
    console.log("api call - getPairingsTicker")
    return this.executeApiRequest(this.apiEventUrl + eventId + this.getPairingsExtension + division, 'GET', null);
  }

  getPairingsForRound(eventId: string, division: string, round: number): Observable<AxiosResponse<apiResponse<roundPairingWrapper>>> {
    console.log("api call - getPairingsTickerForRound")
    return this.executeApiRequest(this.apiEventUrl + eventId + this.getPairingsExtension + division + this.getRoundExtension + round, 'GET', null);
  }

  getStandings(eventId: string, division: string): Observable<AxiosResponse<apiResponse<apiStandings>>> {
    console.log("api call - getStandings")
    return this.executeApiRequest(this.apiEventUrl + eventId + this.getStandingsExtension + division, 'GET', null);
  }

  getPrizeoutStandings(eventId: string): Observable<AxiosResponse<apiResponse<apiPrizeoutStandingsEvent>>> {
    console.log("api call - getStandings")
    return this.executeApiRequest(this.apiEventUrl + eventId + this.getPrizeoutStandingsExtension, 'GET', null);
  }

  getStreamData(eventId: string): Observable<AxiosResponse<apiResponse<streamDataResponse>>> {
    console.log("api call - getStreamData")
    return this.executeApiRequest(this.apiEventUrl + eventId + this.getStreamDataExtension, 'GET', null);
  }

  getEndOfRoundMessage(eventId: string, division: string): Observable<AxiosResponse<apiResponse<toolkitResult>>> {
    console.log("api call - getEndOfRoundMessage")
    return this.executeApiRequest(this.apiEventUrl + eventId + this.getEndOfRoundExtension + division, 'GET', null);
  }

  getExportedTdf(eventId: string): Observable<AxiosResponse<apiResponse<exportedTdf>>> {
    console.log("api call - getExportedTdf")
    return this.executeApiRequest(this.apiEventUrl + eventId + this.getExportedTdfExtension, 'GET', null);
  }

  postImportVideoGameTeamsCsv(postBody: string): Observable<AxiosResponse<apiResponse<LibraryEvent>>> {
    console.log("api call - postImportVideoGameTeamsCsv")
    return this.executeApiRequest(this.apiAnalyticsUrl + this.importExtension + this.vgTeamsCsvExtension, 'POST', postBody);
  }

  postFusionImport(postBody: string, eventId: string): Observable<AxiosResponse<apiResponse<FusionTdfConfirmation>>> {
    console.log("api call - getFusionImport")
    return this.executeApiRequest(this.apiEventUrl + eventId + this.fusionExtension + this.postFusionImportExtension, 'POST', postBody);
  }

  getFusionExportCore(eventId: string): Observable<AxiosResponse<apiResponse<apiExportedFusionTdf>>> {
    console.log("api call - getFusionExportCore")
    return this.executeApiRequest(this.apiEventUrl + eventId + this.fusionExtension + this.getFusionExportCoreExtension, 'GET', null);
  }

  getFusionExportSupport(eventId: string, channelNumber: number): Observable<AxiosResponse<apiResponse<apiExportedFusionTdf>>> {
    console.log("api call - getFusionExportSupport")
    return this.executeApiRequest(this.apiEventUrl + eventId + this.fusionExtension + this.getFusionExportSupportExtension + channelNumber, 'GET', null);
  }

  getPrizeoutStandingsRecords(eventId: string): Observable<AxiosResponse<apiResponse<apiPrizeoutStandingsEvent>>> {
    console.log("api call - getPrizeoutStandingsRecords")
    return this.executeApiRequest(this.apiEventUrl + eventId + this.getPrizeoutStandingsExtension, 'GET', null);
  }

  putPrizeoutRecordsChanges(postBody: string): Observable<AxiosResponse<apiResponse<null>>> {
    console.log("api call - putPrizeoutStandingsChanges")
    return this.executeApiRequest(this.apiPrizeoutUrl + this.putPrizeoutChangesExtension, 'PUT', postBody);
  }

  executeApiRequest(url: string, method: Method, body: string | null) {
    try {
      return from(axios.request({
        url: url,
        method: method,
        data: body,
        headers: this.axiosConfig.headers,
        params: this.axiosConfig.params
      })
      .catch(error => {
        if (error) {
        console.log(error)
        }
        if (error.response) {
        console.log(error.response)
        return error.response;
        }
        return error;
    }));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const serverError = error as AxiosError;
        if (serverError && serverError.response) {
          console.log(serverError.response.data);
          console.log(serverError.response.status);
          console.log(serverError.response.headers);
        }
      } else {
        console.error(error);
      }
      return from([]);
    }
  }
}