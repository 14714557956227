import { useState, useEffect, Fragment } from "react";
import '../../components/glassTheme/Common.scss';
import '../../components/glassTheme/Switch.scss';
import './PairingsList.scss';
import React from 'react';
import { BehaviorSubject, Subscription } from "rxjs";
import Loader, { SpinnerType } from "../../components/Spinner/Loader";
import SearchBar from "../../components/search-bar/SearchBar";
import { GiKiwiBird } from "react-icons/gi";
import FilterButton, { FilterButtonType } from "../../components/filter-button/FilterButton";
import Match from "../Match/Match";
import { matchViewModel } from "../Match/Match.ViewModel";
import { streamDataViewModel } from "../StreamDataControls/StreamDataControls.ViewModel";
import { pairingsViewModel } from "./PairingsList.ViewModel";
import { matchOutcome } from "../../Models/Common/MatchOutcome";
interface PairingsSubviewProps {
    pairingsSubject: BehaviorSubject<pairingsViewModel | null>;
    streamDataSubject: BehaviorSubject<streamDataViewModel | null>;
    highlightedMatch: BehaviorSubject<matchViewModel | null>;
    toggleHighlightedMatch: (match: matchViewModel) => void;
    toggleStreamMatch: (match: matchViewModel) => void;
    togglePlayerToWatch: (playerId: string) => void;
    toggleFeatureMatch: (match: matchViewModel) => void;
}

enum PairingsFilterType {
    FeatureMatch,
    IsPlayerToWatch,
    StreamMatch,
    StillPlaying,
    Search,
    Default,
}

const PairingsList: React.FC<PairingsSubviewProps> = ({ pairingsSubject, streamDataSubject, highlightedMatch, toggleHighlightedMatch, toggleStreamMatch, togglePlayerToWatch, toggleFeatureMatch: toggleFeatureMatch }) => {
    const [matches, setMatches] = useState<matchViewModel[]>([]);
    const [streamData, setStreamData] = useState<streamDataViewModel | null>(null);
    const [highlightedMatchState, setHighlightedMatchState] = useState<matchViewModel | null>(null);
    const [pairingsFilter, setPairingsFilter] = useState<PairingsFilterType>(PairingsFilterType.Default);
    const [searchTerm, setSearchTerm] = useState<string>('');

    useEffect(() => {
            var subs: Subscription[] = [];
            subs.push(pairingsSubject.subscribe((pairings) => setMatches([...pairings?.matches ?? []])));
            subs.push(streamDataSubject.subscribe(setStreamData));
            subs.push(highlightedMatch.subscribe(setHighlightedMatchState));
            return () => subs.forEach(s => s.unsubscribe());
    }, [pairingsSubject, streamDataSubject, highlightedMatch]);

    if (matches === null || matches.length === 0) {
        return <Loader showMessage={true} spinnerType={SpinnerType.FullPage} />;
    }

    const filteredMatches = matches.filter(match => {
        switch (pairingsFilter) {
            case PairingsFilterType.FeatureMatch:
                return match.isFeatureMatch;
            case PairingsFilterType.StillPlaying:
                return match.outcome === matchOutcome.playing;
            case PairingsFilterType.IsPlayerToWatch:
                return match.player1.isPlayerToWatch || (match.player2 && match.player2.isPlayerToWatch);
            case PairingsFilterType.StreamMatch:
                return match.isStreamMatch;
            case PairingsFilterType.Search:
                if (searchTerm === "") {
                    return true;
                }
                return match.player1.name.toLowerCase().includes(searchTerm.toLowerCase())
                || match.player2 && match.player2.name.toLowerCase().includes(searchTerm.toLowerCase())
                || match.tableNumber.toString().includes(searchTerm)
                || match.player1.playerId.toLowerCase().includes(searchTerm.toLowerCase())
                || match.player2 && match.player2.playerId.toLowerCase().includes(searchTerm.toLowerCase())
            default:
                return true;
        }
    });

    const pairingsFilterValueChange = (value: PairingsFilterType) => {
        if (pairingsFilter === value) {
            setPairingsFilter(PairingsFilterType.Default);
            return;
        }
        setPairingsFilter(value);
        setSearchTerm("");
    }

    const searchTermValueChange = (value: string) => {
        setPairingsFilter(PairingsFilterType.Search);
        setSearchTerm(value);
    }

    return (
        <div className="matchesControls">
        <Fragment key={filteredMatches.length}>
            <ul className="matches">
                {filteredMatches.map((match, index) => (
                    <li className="match" key={`${match.tableNumber}-${index}`}>
                        <Match
                            match={match}
                            streamDataSubject={streamDataSubject}
                            isHighlightedMatch={match.tableNumber === highlightedMatchState?.tableNumber}
                            toggleHighlightedMatch={toggleHighlightedMatch}
                            toggleStreamMatch={toggleStreamMatch}
                            togglePlayerToWatch={togglePlayerToWatch}
                            toggleFeatureMatch={toggleFeatureMatch}
                        />
                    </li>
                ))}
                {filteredMatches.length === 0 && <div className="searchError">
                    <label className="glass-h2"><i>No matches found for current filters.</i></label>
                    <GiKiwiBird className="searchErrorIcon"/>
                        </div>}
            </ul>
        </Fragment>
        <div className="filterControls">
            <FilterButton
                isToggled={pairingsFilter === PairingsFilterType.IsPlayerToWatch}
                onToggle={() => pairingsFilterValueChange(PairingsFilterType.IsPlayerToWatch)}
                iconType={FilterButtonType.ToWatch}/>
            <FilterButton
                isToggled={pairingsFilter === PairingsFilterType.StreamMatch}
                onToggle={() => pairingsFilterValueChange(PairingsFilterType.StreamMatch)}
                iconType={FilterButtonType.StreamMatch}/>
            <FilterButton
                isToggled={pairingsFilter === PairingsFilterType.FeatureMatch}
                onToggle={() => pairingsFilterValueChange(PairingsFilterType.FeatureMatch)}
                iconType={FilterButtonType.FeatureMatch}/>
            <FilterButton
                isToggled={pairingsFilter === PairingsFilterType.StillPlaying}
                onToggle={() => pairingsFilterValueChange(PairingsFilterType.StillPlaying)}
                iconType={FilterButtonType.StillPlaying}/>
            <SearchBar
                searchTerm={searchTerm}
                onSearch={searchTermValueChange}/>
        </div>
    </div>
    );
}

export default PairingsList;