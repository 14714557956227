import { BehaviorSubject, debounceTime } from "rxjs";
import { ApiConnectService } from "../../Services/ApiConnectService";
import { TournamentDataService } from "../../Services/TournamentDataService";
import { NavbarData, NavbarProperties, SyncToolConfigData } from "../../components/navbar/Navbar.ViewModel";
import { PrizeoutStandingViewModel } from "../PrizeoutStanding/PrizeoutStanding.ViewModel";
import { PrizeoutEventData } from "../PrizeoutEventSelector/PrizeoutEventSelector.ViewModel";
import { NavbarSyncToolIcon } from "../../components/navbar/NavbarSyncTool/NavbarSyncTool";
import { CommonFormatsService as formats } from "../../Services/CommonFormatsService";
import { Subscription } from "rxjs";
import { PrizeoutRecordsChangesTransfer } from "../../Models/Inbound/PrizeoutRecordsChangesTransfer";
import { prizeoutPrizeType } from "../../Models/Common/PrizeoutPrizeType";

export class PrizeoutDashboardViewModel {
    navbar: NavbarProperties
    selectedEvents = new BehaviorSubject<PrizeoutEventData[]>([]);
    combinedStandings = new BehaviorSubject<PrizeoutStandingViewModel[]>([]);
    currentUsername = new BehaviorSubject<string>('');
    isReadOnly: boolean;
    refreshStandingsTrigger = new BehaviorSubject<void>(void 0);
    pushPrizeoutEditsTrigger = new BehaviorSubject<void>(void 0);

    standingsSubscription: Subscription | null;

    acs = new ApiConnectService();
    tournamentService = new TournamentDataService(this.acs);

    constructor(
        navbarProperties: NavbarProperties,
        isReadOnly: boolean = false) {
        this.navbar = navbarProperties;
        this.isReadOnly = isReadOnly;

        const urlParams = new URLSearchParams(window.location.search);
        const userParam = urlParams.get('user');
        if (userParam) {
            this.updateUsername(userParam);
        }
        this.navbar.data.next(
            new NavbarData(
                "Prize Out Tools")
        );
        this.navbar.syncToolConfig.isSyncActive.next(false);
        this.navbar.syncToolConfig.isSyncFeatureEnabled.next(true);
        this.navbar.syncToolConfig.data.next(new SyncToolConfigData(
            false,
            null,
            NavbarSyncToolIcon.Disabled,
            "Data sync",
            "Enable for background updates",
            ""
        ));

        // Actively tries to save local changes when one is detected
        this.combinedStandings.pipe(
            debounceTime(3000)
        ).subscribe(standings => {
            var changes = standings.map(standing => {
                var converted = standing.toPrizeoutRecordsChangeIfEdited(this.currentUsername.value ?? "No user")
                if (converted !== null) {
                    console.log("Preparing to push prizeout fulfilment update: " + converted.prizeoutUid + " (" + standing.firstName + " " + standing.lastName + ") with " + converted.updatedPrizeFulfilments.length + " changes");
                }
                return converted;
            }).filter(change => change !== null);

            if (changes.length > 0) {
                console.log("Pushing prizeout changes: " + changes.length);
                var transfer = new PrizeoutRecordsChangesTransfer();
                transfer.updatedRecords = changes;
                this.tournamentService.PutPrizeoutRecordsChanges(transfer).subscribe(result => {
                    console.log("Pushed prizeout changes.");
                    this.refreshData(); // Manually get the latest data after pushing changes
                });
            }
        });

        // Passively loads standings data from server, with optional manual trigger
        this.selectedEvents.subscribe(selectedEvents => {
            var errorDetected = false;
            if (this.standingsSubscription) {
                this.standingsSubscription.unsubscribe();
            }

            if (selectedEvents.length > 0) {
                this.standingsSubscription = this.tournamentService.GetPrizeoutStandingsRecordsContinuous(
                selectedEvents.map(event => event.eventId),
                15000,
                this.navbar.syncToolConfig.isSyncActive,
                this.refreshStandingsTrigger
            ).subscribe(results => {
                // First, remove any de-selected events from the list of displayed standings
                const selectedEventIds = new Set(selectedEvents.map(event => event.eventId));
                const filteredStandings = this.combinedStandings.getValue().filter(standing => selectedEventIds.has(standing.eventId));
                this.combinedStandings.next(filteredStandings);
                if (!results || results.length === 0) {
                    console.error("Prizing standings results are null.");
                    return;
                }
                var goodResults = results.filter(result => result?.isAnySuccess()) // Remove bad responses, will need to collect and handle them
                goodResults.sort((a, b) => (a.result?.eventId ?? "").localeCompare(b.result?.eventId ?? "")) // Sort by eventId
                const convertedStandings = goodResults.flatMap(apiStandings => {
                try {
                    if (apiStandings && apiStandings.isSuccessNoContent()) {
                        console.log("No content for event ");
                        return []; }
                    if (!apiStandings || !apiStandings.isSuccess || !apiStandings.result) {
                        errorDetected = true;
                        console.error(apiStandings?.result)
                        console.error("Error detected in standings results for event " + apiStandings?.result?.eventId);
                        return [];
                    }
                    return apiStandings?.result ? apiStandings.result.divisions?.flatMap(division => {
                    return division.standings.map(standing => {
                        try {
                            return {
                                standing,
                                apiResult: apiStandings.result!,
                                division
                            };
                        } catch (error) {
                            console.log("Error converting standing: " + error);
                            return null;
                        }
                    })}) : null
                } catch (error) {
                    console.log("Error converting division " + error);
                    return null;
                }}).filter(standing => standing !== null);
                console.log("Converted standings: " + convertedStandings.length);

                const existingStandings = this.combinedStandings.getValue();
                const updatedStandingsMap = new Map<string, PrizeoutStandingViewModel>();

                // Add existing standings to the map
                existingStandings.forEach(standing => {
                    updatedStandingsMap.set(standing.prizeoutUid, standing);
                });

                // Merge new standings
                const updatedPrizeoutUids = new Set<string>();
                convertedStandings.forEach(({ standing, apiResult, division }) => {
                    const existingStanding = updatedStandingsMap.get(standing.prizeoutUid);
                    const selectedEvent = selectedEvents.find(event => event.eventId === apiResult.eventId);
                    if (existingStanding) {
                        existingStanding.updateServerData(standing, apiResult, division, selectedEvent?.details ?? null);
                    } else {
                        updatedStandingsMap.set(standing.prizeoutUid, new PrizeoutStandingViewModel(standing, apiResult, division, selectedEvent?.details ?? null));
                    }
                    updatedPrizeoutUids.add(standing.prizeoutUid);
                });

                // Remove any standings that are no longer on an event that did not error and has no unsynched changes
                const notUpdatedStandings = existingStandings.filter(standing => {
                    const matchingResult = goodResults.find(result => result.result?.eventId === standing.eventId);
                    return matchingResult && !standing.isEdited;
                });

                notUpdatedStandings.forEach(standing => {
                    updatedStandingsMap.delete(standing.prizeoutUid);
                });

                const highlightedError = results.find(result => !result.isAnySuccess()) ?? null;
                // Convert map back to array
                const mergedStandings = Array.from(updatedStandingsMap.values());
                this.combinedStandings.next(mergedStandings);

                // Update navbar state
                this.navbar.syncToolConfig.data.next(new SyncToolConfigData(
                false,
                highlightedError ?? results[0] ?? null,
                NavbarSyncToolIcon.Sync,
                "Prize Out",
                "Standings data sync",
                "Updated: " + formats.formatOptionalDateCompact(new Date().toISOString()),
                ));
            });
            }
        });
        
        this.refreshData();      
    }

    refreshData = () => {
        this.refreshStandingsTrigger.next();
    }

    pushPrizeoutEdits = () => {
        this.pushPrizeoutEditsTrigger.next();
    }

    public updateSelectedEvents = (selectedEvents: PrizeoutEventData[]) => {
        console.log("Finished selecting events");
        this.selectedEvents.next(selectedEvents);
    }

    public updateUsername = (username: string) => {
        console.log("Updating username to " + username);
        this.currentUsername.next(username);

        // Update the user query param in the URL
        const url = new URL(window.location.href);
        url.searchParams.set('user', username);
        window.history.replaceState({}, '', url.toString());
    }

    public editPrizeoutComment = (prizeoutUid: string, comment: string | null) => {
        console.log(`Editing comment for ${prizeoutUid} to ${comment}`);
        const standings = this.combinedStandings.getValue();
        const standingToEdit = standings.find(standing => standing.prizeoutUid === prizeoutUid);
        if (standingToEdit) {
            standingToEdit.editComment(comment);
            this.combinedStandings.next(standings);
        } else {
            console.error(`No standing found with prizeoutUid: ${prizeoutUid}`);
        }
    }   

    public editPrizeAwarded = (prizeoutUid: string, prizeGuid: string, newQuantityReceived: number | null) => {
        console.log(`Editing prizeout for ${prizeoutUid} - ${prizeGuid} to ${newQuantityReceived}`);
        const standings = this.combinedStandings.getValue();
        const standingToEdit = standings.find(standing => standing.prizeoutUid === prizeoutUid);
        if (standingToEdit) {
            standingToEdit.editPrizeFulfilment(prizeGuid, newQuantityReceived);
            this.combinedStandings.next(standings);
        } else {
            console.error(`No standing found with prizeoutUid: ${prizeoutUid}`);
        }
    }

    public getStatistics = () => {
        const prizeTypeCounts: { [key: string]: string } = {};
        for (const prizeType of Object.values(prizeoutPrizeType).filter(value => typeof value === 'number') as prizeoutPrizeType[]) {
            const readableType = prizeoutPrizeType.toReadableString(prizeType);
            const quantityEarned = this.combinedStandings.value.reduce((count, standing) => {
            return count + standing.prizefulfilments.filter(prize => prize.type === prizeType).reduce((sum, prize) => sum + (prize.quantityEarned ?? 0), 0);
            }, 0);
            const remoteQuantityReceived = this.combinedStandings.value.reduce((count, standing) => {
            return count + standing.prizefulfilments.filter(prize => prize.type === prizeType).reduce((sum, prize) => sum + (prize.remoteQuantityReceived ?? 0), 0);
            }, 0);
            prizeTypeCounts[readableType] = `${quantityEarned - remoteQuantityReceived} + ${remoteQuantityReceived} = ${quantityEarned}`;
        }
        return `Counts ( Unprized + Prized = Total ):\n` +
            `Players ${this.combinedStandings.value.filter(standing => !standing.isFullyPrizedOut()).length} + ${this.combinedStandings.value.filter(standing => standing.prizesDueAndFullyPrizedOut()).length} = ${this.combinedStandings.value.filter(standing => standing.prizesDue()).length}\n` +
            Object.entries(prizeTypeCounts).map(([type, count]) => `${type}: ${count}`).join('\n');
    }
}