import { useEffect, Fragment } from "react";
import { motion, useAnimationControls } from "framer-motion";
import '../../components/glassTheme/Common.scss';
import '../../components/glassTheme/Switch.scss';
import Loader, { SpinnerType } from "../../components/Spinner/Loader";
import './PrizeoutStanding.scss';
import React from 'react';
import { PrizeoutStandingViewModel } from "./PrizeoutStanding.ViewModel";
import { Subscription } from "rxjs";
import PrizeoutButton from "../PrizeoutButton/PrizeoutButton";
import { FaCheck, FaChevronUp, FaChevronDown } from "react-icons/fa";
import { FaExclamation } from "react-icons/fa6";
import { FaLongArrowAltRight, FaSync } from "react-icons/fa";
import { competitorDivision } from "../../Models/Common/CompetitorDivision";
import IconButton, { IconButtonType } from "../../components/IconButton/IconButton";

interface PrizeoutStandingProps {
    viewModel: PrizeoutStandingViewModel;
    editPrizeoutComment: (prizeoutUid: string, comment: string | null) => void;
    editPrizeAwarded: (prizeoutUid: string, prizeGuid: string, prizePointsAwarded: number | null) => void;
}

const Standing: React.FC<PrizeoutStandingProps> = ({ viewModel, editPrizeoutComment, editPrizeAwarded }) => {
    const animationControls = useAnimationControls();
    const selectedAnimation = useAnimationControls();

    const [comment, setComment] = React.useState(viewModel.localComment ?? "");
    const [isShowingHistory, setIsShowingHistory] = React.useState(false);

    useEffect(() => {
        animationControls.start("onscreen");
        var subs: Subscription[] = [];
        return () => subs.forEach(s => s.unsubscribe());
    }, [animationControls]);

    useEffect(() => {
        if (isShowingHistory) {
            selectedAnimation.start("onscreen");
        } else {
            selectedAnimation.start("offscreen");
        }
    }, [isShowingHistory]);

    useEffect(() => {
        setComment(viewModel.remoteComment ?? "");
    }, [viewModel]);

    if (viewModel === null) {
        return <Loader showMessage={true} spinnerType={SpinnerType.FullPage} />;
    }

    const animationVariants = {
        offscreen: { opacity: 0, y: -10 },
        onscreen: { opacity: 1, y: 0 }
    };

    const selectedAnimationVariantsLeft = {
        offscreen: { opacity: 0, x: 50 },
        onscreen: { opacity: 1, x: 0, transition: { duration: 0.2 } }
    };

    const selectedAnimationVariantsRight = {
        offscreen: { opacity: 0, x: -50 },
        onscreen: { opacity: 1, x: 0, transition: { duration: 0.2 } }
    };

    const commentValueChange = (value: string) => {
        setComment(value);
    }

    const cancelCommentValueChange = () => {
        setComment(viewModel.remoteComment ?? "");
    }

    const isCommentUpdated = () => {
        return (viewModel.remoteComment ?? "") !== (comment ?? "");
    }

    return (
        <Fragment>
        <motion.div
        className="prizeoutStanding-list-item"
            initial="offscreen"
            animate={animationControls}
            variants={animationVariants}
            transition={{ duration: 0.2 }}
            >
            <div
                className={isShowingHistory ? "prizeoutStanding-card dark-glass-card" : viewModel.isDropped() ? "prizeoutStanding-card dim-glass-card" : "prizeoutStanding-card glass-card"}>
                <div className="prizeoutStanding-record-container">
                    <div className="prizeoutStanding-position-box">
                        {!viewModel.isFinal && <label className="glass-caption italic">Not final</label>}
                        <label className="glass-h2">{viewModel.formattedPosition()}</label>
                    </div>
                    <label className="glass-h4">{viewModel.wins} / {viewModel.losses} / {viewModel.ties}</label>
                </div>
                <div className="prizeoutStanding-player-details">
                    <div className="data-pair">
                        <label className="glass-caption tLeft italic">{competitorDivision.name(viewModel.division)} {viewModel.isDropped() && "Drop R" + viewModel.dropRound}</label>
                        <label className="glass-h2 tLeft">{viewModel.fullName()}</label>
                    </div>
                    <label className="glass-caption italic tLeft">{viewModel.eventName}</label>
                </div>
                <div className="prizeoutStanding-prizeoutButtons-container">
                    <div className="prizeoutStanding-markerContainer">
                        {viewModel.prizesDueAndFullyPrizedOut() && 
                        <div key="markerComplete" className="prizeoutStanding-markerComplete">
                            <FaCheck className="prizeoutStanding-markerIcon"/>
                        </div>}
                        {viewModel.commentIsImportant() && 
                        <div key="markerWarning" className="prizeoutStanding-markerWarning">
                            <FaExclamation className="prizeoutStanding-markerIcon"/>
                        </div>}
                    </div>
                    {viewModel.prizefulfilments && viewModel.prizefulfilments.length > 0
                        ? viewModel.prizefulfilments
                            .sort((a, b) => a.PriorityOrder() - b.PriorityOrder())
                            .map((prizefulfillment) => (
                                <PrizeoutButton
                                    key={prizefulfillment.prizeGuid}
                                    fulfilment={prizefulfillment}
                                    hoverText={null}
                                    onToggleEdit={(prizeGuid, updatedValue) => editPrizeAwarded(viewModel.prizeoutUid, prizeGuid, updatedValue)} />
                            ))
                        : <label className="glass-h4 italic">No prizes due</label>
                    }
                </div>
                <div className="prizeoutStanding-comment-container">
                    <div className="prizeout-comment-edit-container">
                        <textarea
                            className={`prizeout-commentInput${comment ? '-populated' : ''}`}
                            placeholder={viewModel.remoteComment ? "Change comment..." : "Add a comment..."}
                            value={comment}
                            onChange={(e) => commentValueChange(e.target.value)}
                        />
                        {viewModel.localComment !== viewModel.remoteComment && viewModel.localComment !== null && <div className='prizeoutButton-corner-element'><FaSync className='sync-badge-icon'/></div>}
                        {isCommentUpdated() && (
                            <div className="prizeout-comment-edit-button-container">
                                <IconButton isEnabled={true} hasShadow={false} iconType={IconButtonType.Cross} label="Delete" hasBackground={true} onPress={() => { cancelCommentValueChange() }}/>
                                <IconButton isEnabled={true} hasShadow={false} iconType={IconButtonType.Tick} label="Delete" hasBackground={true} onPress={() => { editPrizeoutComment(viewModel.prizeoutUid, comment) }}/>
                            </div>)}
                    </div>
                </div>
                <div className="prizeoutStanding-history-button-container" onClick={() => setIsShowingHistory(!isShowingHistory)}>
                    {!isShowingHistory ? <FaChevronDown className="event-data-action-icon" /> : <FaChevronUp className="event-data-action-icon" />}
                </div>
            </div>
        </motion.div>
        {isShowingHistory && 
            <div className="prizeoutStanding-history-container">
                <label className="glass-h2">Edit history</label>
                <div className="prizeoutStanding-history-list">
                    {viewModel.editHistory && viewModel.editHistory.length > 0 ? viewModel.editHistory.slice().reverse().map((historyItem, historyIndex) => (
                        <div key={historyIndex} className="prizeoutStanding-history-item">
                            <label className="prizeoutStanding-history-data-label">{historyItem.timestamp}</label>
                            <label className="prizeoutStanding-history-data-label bold">{historyItem.adminName && historyItem.adminName.trim() !== "" ? historyItem.adminName : "no name"}</label>
                            <div className="prizeoutStanding-history-change-list">
                                {historyItem.changes.map((changeItem, changeIndex) => (
                                    <div key={changeIndex} className="prizeoutStanding-history-change">
                                        <label className="glass-caption">{changeItem.key}</label>
                                        <label className="glass-caption">{changeItem.beforeValue}</label>
                                        <FaLongArrowAltRight className="prizeoutStanding-history-change-arrow"/>
                                        <label className="glass-caption">{changeItem.afterValue}</label>
                                    </div>
                                ))}
                            {historyIndex < viewModel.editHistory.length - 1 && <div className="glass-divider"></div>}
                            </div>
                        </div>
                    )) : <label className="glass-caption italic">No edits made</label>}
                </div>  
            </div>
        }
        </Fragment>
    )
}

export default Standing;