import { competitorDivision } from "../Common/CompetitorDivision";
import { apiPrizeFulfilment } from "../Prizeout/ApiPrizeFulfilment";

export class PrizeoutRecordsChangesTransfer {
    updatedRecords: PrizeoutRecordsChange[];
}

export class PrizeoutRecordsChange {
    prizeoutUid: string;
    eventId: string;
    division: competitorDivision;
    playerId: string;
    firstName: string;
    lastName: string;
    updatedPrizeFulfilments: apiPrizeFulfilment[];
    position: number | null;
    comment: string | null;
    adminName: string | null;
    lastUpdated: string | null;
}