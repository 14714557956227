import React, { useEffect, useState } from 'react';
import "./NavbarSignInTool.scss"

interface NavbarSignInToolProps {
}

const NavbarSignInTool: React.FC<NavbarSignInToolProps> = ({}) => {
    const [isSignedIn, setIsSignedIn] = useState<boolean | null>(null);
    const [signedInAsName, setSignedInAsName] = useState("");
    const [signedInAsRole, setSignedInAsRole] = useState("");

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await fetch('/.auth/me');
                const payload = await response.json();
                if (payload.clientPrincipal) {
                    const { userDetails, userRoles } = payload.clientPrincipal;
                    setSignedInAsName(userDetails);
                    setSignedInAsRole(userRoles.join(', '));
                    setIsSignedIn(true);
                } else {
                    setIsSignedIn(false);
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
                setIsSignedIn(false);
            }
        };

        fetchUserData();
    }, []);

    const login = () => {
        window.location.href = "/.auth/login/aad";
    }

    const logout = async () => {
        const postLogoutRedirectUri = encodeURIComponent("https://toolkit.polygondreams.net");
        const microsoftLogoutUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/logout?post_logout_redirect_uri=${postLogoutRedirectUri}&logout_hint=`;
        window.location.href = `/.auth/logout?post_logout_redirect_uri=${encodeURIComponent(microsoftLogoutUrl)}`;
    };

    const renderView = () => {
        return (
            <div className="navbarSignInTool-container">
                {isSignedIn === null ? null : (
                    <>
                        {isSignedIn ? (
                            <>
                                <label className="navbarSignInTool-title">{signedInAsName}</label>
                            </>
                        ) : (
                            <label className="navbarSignInTool-title">Account required</label>
                        )}
                        <label className="navbarSignInTool-button" onClick={() => {
                            if (isSignedIn) {
                                logout();
                            } else {
                                login();
                            }
                        }}>
                            {isSignedIn ? "Sign Out" : "Sign In"}
                        </label>
                    </>
                )}
            </div>
        );
    }

    return (
        <div
            className="navbarSignInTool">
            {renderView()}
        </div>
    )
};

export default NavbarSignInTool;