export enum prizeoutPrizeType {
        Bag,
        Booster,
        Medal,
        Playmat,
        Promo,
        PrizePoints,
        Trophy,
        Other,
}

export namespace prizeoutPrizeType {
        export function toReadableString(awardType: prizeoutPrizeType, plural: boolean = false): string {
                switch (awardType) {
                        case prizeoutPrizeType.Bag:
                                return plural ? "Bags" : "Bag";
                        case prizeoutPrizeType.Booster:
                                return plural ? "Boosters" : "Booster";
                        case prizeoutPrizeType.Medal:
                                return plural ? "Medals" : "Medal";
                        case prizeoutPrizeType.Playmat:
                                return plural ? "Playmats" : "Playmat";
                        case prizeoutPrizeType.Promo:
                                return plural ? "Promos" : "Promo";
                        case prizeoutPrizeType.PrizePoints:
                                return plural ? "Points" : "Point";
                        case prizeoutPrizeType.Trophy:
                                return plural ? "Trophies" : "Trophy";
                        case prizeoutPrizeType.Other:
                                return "Other";
                        default:
                                throw new Error(`Invalid award type: ${awardType}`);
                }
        }
        export function name(awardType: prizeoutPrizeType): string {
                switch (awardType) {
                        case prizeoutPrizeType.Bag:
                                return "Bag";
                        case prizeoutPrizeType.Booster:
                                return "Booster";
                        case prizeoutPrizeType.Medal:
                                return "Medal";
                        case prizeoutPrizeType.Playmat:
                                return "Playmat";
                        case prizeoutPrizeType.Promo:
                                return "Promo";
                        case prizeoutPrizeType.PrizePoints:
                                return "Point";
                        case prizeoutPrizeType.Trophy:
                                return "Trophy";
                        case prizeoutPrizeType.Other:
                                return "Other";
                        default:
                                return "Other";
                        
                }
        }
}